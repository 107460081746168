import { useForm } from '@mantine/form';
import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  ModalProps,
  Select,
  Stack,
  Textarea,
  TextInput,
  Checkbox,
  Drawer,
} from '@mantine/core';
import React from 'react';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { validateRequired } from '../../../../validators';
import { useFormApiErrorHandler } from '../../../../hooks/useFormApiErrorHandler';
import ErrorText from '../../../../components/ErrorText';
import { useEasyGeneratePostMutation } from '../../store/generated-posts/api';
import { BlogLength, EasyGeneratePost, Languages } from '../../model/EasyGeneratePost';
import TopicalContent from './TopicalContent';

const languagesArr = Object.values(Languages);
const blogLengthArr = Object.values(BlogLength);

export default function EasyPostGenerationModal({ onClose, ...props }: ModalProps) {
  const [addGeneration, { isLoading, error }] = useEasyGeneratePostMutation();

  const form = useForm<EasyGeneratePost>({
    initialValues: {
      topic: '',
      // TODO: Select user language
      language: Languages.GERMAN,
      blogLength: BlogLength.MEDIUM,
    },
    validate: {
      topic: validateRequired('Topic'),
      language: validateRequired('Language'),
      blogLength: validateRequired('Blog length'),
    },
  });

  useFormApiErrorHandler(form, error);

  const [opened, { open, close }] = useDisclosure(false);

  // TODO: Add max length to the text field matching the backend
  return (
    <Modal withCloseButton onClose={onClose} title="Generate a new Post!" {...props} centered>
      <LoadingOverlay visible={isLoading} overlayBlur={1} />

      <form
        onSubmit={form.onSubmit((values) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          addGeneration({ ...values }).then((res) => {
            if (!('error' in res)) {
              form.reset();
              onClose();
              showNotification({
                title: 'Success',
                message: 'Your post is being generated!',
              });
            }
            else {
              showNotification({
                title: 'Something went wrong',
                message: 'Make sure you have enough token for post generation',
              });
            }
          });
        })}
        noValidate
      >
        <Stack>
          <TextInput
            required
            label="Topic"
            placeholder="How to write a good blog post"
            {...form.getInputProps('topic')}
          />

          <TextInput
            label="Product to promote"
            placeholder="Wordgenie - www.wordgenie.de - The best tool for creating blog posts, soon."
            {...form.getInputProps('product')}
          />

          <TextInput
            label="Target Audience"
            placeholder="SEO Experts"
            autoComplete="off"
            {...form.getInputProps('target_audience')}
          />

          <Textarea
            label="Additional Context"
            placeholder="I am helping a people to get started with writing blog posts and SEO-optimizations."
            autoComplete="off"
            autosize
            maxRows={4}
            {...form.getInputProps('context')}
          />

          <Select
            required
            label="Length of the Blog"
            data={blogLengthArr}
            {...form.getInputProps('blogLength')}
          />

          <Select
            required
            label="Language of the Blog"
            data={languagesArr}
            {...form.getInputProps('language')}
          />


          <Checkbox
            required
            label="FAQ generation"
            defaultChecked={false}
            {...form.getInputProps('faq')}
          />

          <Checkbox
            required
            label="Image generation"
            defaultChecked={false}
            {...form.getInputProps('picture')}
          />

          {form.errors.nonFieldErrors && <ErrorText>{form.errors.nonFieldErrors}</ErrorText>}

          <Group position="right">
            <Button variant="default" onClick={onClose}>
              Cancel
            </Button>
            <Drawer opened={opened} onClose={close}>
              <TopicalContent title="Topical Authority" subtitle="Generate in bulk" topic={form.values.topic} language={form.values.language} onClose={close} />
            </Drawer>
            <Button disabled={!form.values.topic || !form.values.language} onClick={open}>Bulk - Topical Authority</Button>
            <Button type="submit">Generate</Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}
