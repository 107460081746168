import { baseApi } from '../../../../store/api';
import { Instance } from '../../model/Instance';

export const api = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getInstances: builder.query<Instance[], void>({
      query: () => 'instances/',
      providesTags: [{ type: 'Instance', id: 'LIST' }],
    }),

    getInstance: builder.query<Instance, string>({
      query: (id: string) => `instances/${id}/`,
      providesTags: (_, __, id) => [{ type: 'Instance', id }],
    }),

    addInstance: builder.mutation({
      query: <V extends Instance>(instance: V) => ({
        url: 'instances/',
        method: 'POST',
        body: instance,
      }),
      invalidatesTags: [
        { type: 'Instance', id: 'LIST' },
        { type: 'BlogPost', id: 'LIST' },
      ],
    }),

    updateInstance: builder.mutation({
      query: (instance: Partial<Instance> & Pick<Instance, 'id'>) => ({
        url: `instances/${instance.id}/`,
        method: 'PATCH',
        body: instance,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: 'Instance', id },
        { type: 'Instance', id: 'LIST' },
        { type: 'BlogPost', id: 'LIST' },
      ],
    }),

    deleteInstance: builder.mutation({
      query: (id: string) => ({
        url: `instances/${id}/`,
        method: 'DELETE',
      }),

      invalidatesTags: (_, __, id) => [
        { type: 'Instance', id },
        { type: 'Instance', id: 'LIST' },
        { type: 'BlogPost', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetInstancesQuery,
  useGetInstanceQuery,
  useAddInstanceMutation,
  useUpdateInstanceMutation,
  useDeleteInstanceMutation,
} = api;
