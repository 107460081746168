/* eslint-disable camelcase,@typescript-eslint/no-explicit-any */
import { MantineReactTable, MantineReactTableProps } from 'mantine-react-table';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import React from 'react';
import { ActionIcon, Flex, Menu, Text, Tooltip } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconEdit, IconTrash, IconEye } from '@tabler/icons-react';
import { useConfirmDelete } from '../../../hooks/useConfirmDelete';

interface StyledTableProps<T extends Record<string, any>> extends MantineReactTableProps<T> {
  isLoading?: boolean;
  isFetching?: boolean;
  pinRight?: keyof T;
  onDelete?: (data: T) => void;
  onEdit?: (data: T) => void;
  onView?: (data: T) => void;
  error?: SerializedError | FetchBaseQueryError;
}

export default function StyledTable<T extends Record<string, any>>({
  isLoading,
  isFetching,
  error,
  initialState,
  state,
  onDelete,
  onEdit,
  onView,
  renderRowActions,
  mantineToolbarAlertBannerProps,
  ...props
}: StyledTableProps<T>) {
  const hasRowActions = !!(onDelete || onEdit || onView || renderRowActions);
  const { columnVisibility, sorting, ...restState } = initialState ?? {};
  const onDeleteWithConfirm = useConfirmDelete({ onDelete });

  return (
    <MantineReactTable<T>
      enableRowActions={hasRowActions}
      positionActionsColumn="last"
      renderRowActions={(rowActionProps) => {
        const { row } = rowActionProps;
        const getRowAction = (
          icon: React.ReactNode,
          label: string,
          func?: (data: T, auxClick?: boolean) => void
        ) =>
          func && (
            <Tooltip label={label}>
              <ActionIcon onClick={() => func(row.original)}>{icon}</ActionIcon>
            </Tooltip>
          );

        return (
          <Flex
            gap="xs"
            sx={{
              '&:last-child': {
                marginRight: 16,
              },
              maxWidth: 'fit-content',
              '& * .mantine-ActionIcon-root:disabled': {
                backgroundColor: 'transparent',
                borderColor: 'transparent',
              },
            }}
          >
            {renderRowActions?.(rowActionProps)}
            {getRowAction(<IconEye />, 'View', onView)}
            {getRowAction(<IconEdit />, 'Edit', onEdit)}
            {getRowAction(<IconTrash />, 'Delete', onDelete ? onDeleteWithConfirm : undefined)}
          </Flex>
        );
      }}
      initialState={{
        columnVisibility: {
          id: false,
          created: false,
          modified: false,
          ...columnVisibility,
        },
        sorting: [
          {
            id: 'created',
            desc: true,
          },
          ...(sorting ?? []),
        ],
        ...restState,
      }}
      state={{
        isLoading,
        showAlertBanner: !!error,
        showProgressBars: isFetching,
        ...state,
      }}
      mantineToolbarAlertBannerProps={{
        color: error ? 'red' : undefined,
        children: error ? ('error' in error && `${error.error}`) || 'Unknown error' : undefined,
        ...mantineToolbarAlertBannerProps,
      }}
      enableColumnResizing
      enableStickyHeader
      enableColumnOrdering
      enableDensityToggle={false}
      {...props}
    />
  );
}
