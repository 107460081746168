import React from 'react';
import { Alert, createStyles } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { Announcement } from '../model/Announcement';
import { useGetAnnouncementsQuery } from '../store/site-settings/api';
import { useSiteSettings } from '../hooks/useSiteSettings';

// TODO: improve styling, show again button
const useStyles = createStyles((theme) => ({
  alert: {
    position: 'fixed',
    top: theme.spacing.xl,
    left: theme.spacing.xl,
    right: theme.spacing.xl,
    zIndex: 9999,
  },
}));

export default function Announcements() {
  const { data: announcements } = useGetAnnouncementsQuery();
  const { siteSettings, hideAnnouncement } = useSiteSettings();
  const { classes } = useStyles();

  const filteredAnnouncements = announcements?.filter(
    (announcement: Announcement) => !siteSettings.hiddenAnnouncementIds.includes(announcement.id)
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {filteredAnnouncements?.map((announcement: Announcement) => (
        <Alert
          key={announcement.id}
          className={classes.alert}
          icon={<IconAlertCircle size="1rem" />}
          title={announcement.title}
          variant="filled"
          onClose={() => {
            hideAnnouncement(announcement.id);
          }}
          withCloseButton
        >
          {announcement.description}
        </Alert>
      )) ?? null}
    </>
  );
}
