import { TextInput, Box, Button } from '@mantine/core';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useStore } from 'react-redux';
import { AppState } from '../../../../store';
import { showNotification } from '@mantine/notifications';
import { useState, FormEvent } from 'react';


interface AddKeywordProps {
  keyword: string;
  domain: string;
  updateData: () => Promise<void>;
}

export default function AddKeywordInputs(props: AddKeywordProps) {

const store = useStore<AppState>();
const [keyword, setKeyword] = useState("");
const [domain, setDomain] = useState("");
const validateKeyword = (inputDomain: string, inputKeyword: string) => {
    console.log(inputDomain.length);
    console.log(inputKeyword.length);
    if (inputDomain.length > 10 && inputKeyword.length >= 2) {
    return true;
    } else {
    return false; }
  };


const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (validateKeyword(domain, keyword) == true) {
    addKeyword(); 
    } else {
    showNotification({
        title: 'Keyword and Domain must be added',
        color: 'red',
        autoClose: 4000,
        message: 'Please add both, a valid Keyword and a valid Domain',
        });
    }
  }

const addKeyword = async () => {
    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken') ?? '',
    };

    if (store.getState().auth.token) {
        headers['Authorization'] = `Token ${store.getState().auth.token}`;
    }


    try {
        const payload = {"domain": domain, "keyword": keyword}
        const response = await axios.post(`${process.env.REACT_APP_API_URL!}/api/v1/keywords/add-keyword/`, payload, { headers } );
        showNotification({
            title: 'Keyword added',
            color: 'green',
            autoClose: 4000,
            message: 'Successfully added Keyword, it might take a while to fetch ranking and show here!',
          });

        await new Promise(resolve => setTimeout(resolve, 4000)); // Sleep for 4 second to callback to main component to show new keyword
        props.updateData();

    } catch (error) {
        console.error('Error adding keyword', error);
        showNotification({
            title: 'Keyword adding failed',
            color: 'red',
            autoClose: 4000,
            message: `Keyword adding failed: ${error}`,
          });
    }
    };

    
  return (
    <>
        <Box
            style={{
            marginTop: "10px",
            marginBottom: "10px",
            border: '1px solid #ccc', // Adds a light gray border
            padding: '10px' // Adds padding inside the box
            }}
        >
        <form
            onSubmit={handleSubmit}
            noValidate
        >
        <div style={{ marginBottom: '10px' }}>
          <h3>Add keywords</h3>
        </div>
        
        <div style={{ 
          display: "flex",
          alignItems: "center",
          justifyContent: 'space-between',
        }}>
          <div style={{ marginRight: "15px" }}>
            <TextInput label="Keyword" placeholder="AI Blogpost generation" id={"keyword"} onChange={(e) => setKeyword(e.target.value)} />
          </div>
          <div style={{ marginRight: "15px" }}>
            <TextInput label="Domain" placeholder="https://wordgenie.de" id={"domain"} onChange={(e) => setDomain(e.target.value)} />
          </div>
          <div style={{ alignSelf: 'stretch' }}>
            <Button style={{ height: '100%' }} type={"submit"}>
              Add Keyword
            </Button>
          </div>
        </div>
        </form>
      </Box>
    </>
  );
}
