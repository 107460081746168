import React from 'react';
import {
  ActionIcon,
  Avatar,
  Box,
  createStyles,
  Flex,
  Skeleton,
  Text,
  Tooltip,
  UnstyledButton,
  Grid,
  UnstyledButtonProps,
} from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';
import { openConfirmModal } from '@mantine/modals';
import { useAuth } from '../../../hooks/useAuth';
import { useUser } from '../../../hooks/useUser';

const useStyles = createStyles((theme) => ({
  user: {
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    padding: theme.spacing.md,
    paddingRight: 0,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
    },
  },
}));

interface UserButtonProps extends UnstyledButtonProps {
  onClick: () => void;
}

export default function UserButton(props: UserButtonProps) {
  const { classes } = useStyles();
  const { user, isLoading } = useUser();
  const { logout } = useAuth();

  const openModal = () =>
    openConfirmModal({
      title: 'Are you sure?',
      labels: { confirm: 'Log out', cancel: 'Cancel' },
      onConfirm: () => logout(),
      overlayProps: {
        center: true,
        blur: 0.5,
      },
      confirmProps: { color: 'red' },
    });

  const showSkeleton = isLoading || !user;

  return (
    <Grid align="center" sx={{ maxWidth: '100%' }}>
      <Grid.Col span="auto" sx={{ maxWidth: '79%' }}>
        <UnstyledButton className={classes.user} {...props}>
          <Flex align="center" gap="sm">
            <Avatar variant="filled" radius="xl">
            </Avatar>

            <Box sx={{ overflow: 'hidden' }}>
              {showSkeleton ? (
                <>
                  <Skeleton width={80} height={14} mb="xs" />
                  <Skeleton width={70} height={12} />
                </>
              ) : (
                <>
                  <Text truncate size="sm" weight={500}>
                    {user?.firstName} {user?.lastName}
                  </Text>
                  <Text truncate color="dimmed" size="xs">
                    {user?.email}
                  </Text>
                </>
              )}
            </Box>
          </Flex>
        </UnstyledButton>
      </Grid.Col>
      <Grid.Col span="content">
        <Tooltip label="Logout">
          <ActionIcon
            ml="auto"
            onClick={() => {
              openModal();
            }}
          >
            <IconLogout />
          </ActionIcon>
        </Tooltip>
      </Grid.Col>
    </Grid>
  );
}
