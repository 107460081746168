import React from 'react';
import { Paper, Text } from '@mantine/core';

interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string;
}

export default class ErrorBoundary extends React.Component<
  React.PropsWithChildren,
  ErrorBoundaryState
> {
  constructor(props: React.PropsWithChildren) {
    super(props);

    this.state = {
      hasError: false,
      errorMessage: '',
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo);
    this.setState({
      hasError: true,
      errorMessage: error.message,
    });
  }

  render() {
    const { hasError, errorMessage } = this.state;

    if (!hasError) {
      const { children } = this.props;
      return children;
    }

    return (
      <Paper p="md" shadow="xs">
        <Text align="center" size="xl" color="red">
          Something went wrong.
        </Text>
        <Text align="center" color="gray">
          {errorMessage}
        </Text>
      </Paper>
    );
  }
}
