import React, { useEffect, useState } from 'react';
import { ActionIcon, Button, Flex, Tooltip } from '@mantine/core';
import { IconWand, IconShare, IconShareOff } from '@tabler/icons-react';
import { MRT_ColumnDef as MRT_COLDEF } from 'mantine-react-table';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { RouteConfig } from '../../../../../../types/RouteConfig';
import { routeConfig as blogGenerationRouteConfig } from './pages/GeneratedPost';
import {
  useDeleteGeneratedPostMutation,
  useGetGeneratedPostsQuery,
} from '../../../../store/generated-posts/api';
import StyledTable from '../../../../components/StyledTable';
import {
  GeneratedPostListItem,
  GenerationStatus,
  isGenerating,
} from '../../../../model/GeneratedPost';
import EasyPostGenerationModal from '../../../../components/modal/EasyPostGenerationModal';
import GenerationStatusIcon from '../../../../components/GenerationStatusIcon';
import { useGetInstancesQuery } from '../../../../store/instances/api';
import { SynchronisationInnerProps } from '../../../../components/modal/SynchronisationContextModal';
import { notifications } from '@mantine/notifications';

const columns: MRT_COLDEF<GeneratedPostListItem>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorKey: 'topic',
    header: 'Topic',
  },
  {
    header: 'Instance',
    Cell: ({ row }) => {
      const { data: instances } = useGetInstancesQuery();

      const instance = instances?.find(
        (inst) => inst.id === row.original.synchronisation?.instance
      );

      if (instance) {
        return instance.name;
      }

      return row.original.synchronisation?.instance ?? null;
    },
  },
  {
    accessorKey: 'status',
    header: 'Generation',
    size: 140,
    maxSize: 140,
    Cell: ({ row }) => <GenerationStatusIcon generationStatus={row.original.status} />,
  },
  {
    accessorKey: 'created',
    header: 'Created at',
  },
  {
    accessorKey: 'modified',
    header: 'Modified at',
  },
];

export default function GeneratedPosts() {
  const navigate = useNavigate();
  const [creationOpened, { open: openCreation, close: closeCreation }] = useDisclosure(false);
  const [rowSelection, setRowSelection] = useState({});
  const [hasGenerating, setHasGenerating] = useState(false);
  const {
    data: generationListItems,
    isLoading,
    error,
    isFetching,
  } = useGetGeneratedPostsQuery(undefined, {
    pollingInterval: hasGenerating ? 5000 : undefined,
  });
  const [delGeneratedPost] = useDeleteGeneratedPostMutation();

  // TODO: remove when websocket is implemented
  useEffect(() => {
    const generating = !!generationListItems?.some((d) => isGenerating(d));
    setHasGenerating(generating);
  }, [generationListItems, setHasGenerating]);

  const openSyncModal = (innerProps: SynchronisationInnerProps) => {
    modals.openContextModal({
      modal: 'synchronisation',
      innerProps,
      title: 'Synchronisation',
    });
  };

  const onView = ({ id }: GeneratedPostListItem) => {
    navigate(`${id}`);
  };

  const onEdit = ({ id }: GeneratedPostListItem) => {
    navigate(`${id}?viewMode=edit`);
  };

  const onDelete = ({ id }: GeneratedPostListItem) => {
    delGeneratedPost(id);
  };

  return (
    <>
      <StyledTable
        columns={columns}
        data={generationListItems ?? []}
        isLoading={isLoading}
        isFetching={hasGenerating ? undefined : isFetching}
        error={error}
        onView={onView}
        onEdit={onEdit}
        onDelete={onDelete}
        getRowId={(row) => row.id}
        enableRowSelection
        onRowSelectionChange={setRowSelection}
        state={{ rowSelection }}
        renderRowActions={({ row }) => {
          const disableSync =
            isGenerating(row.original) || row.original.status === GenerationStatus.FAILED;

          return (
            <Tooltip
              label={disableSync ? 'You cannot sync generating or failed posts' : 'Sync Post'}
            >
              <div>
                <ActionIcon
                  disabled={disableSync}
                  onClick={() => openSyncModal({ generatedPost: row.original })}
                >
                  {disableSync ? <IconShareOff /> : <IconShare />}
                </ActionIcon>
              </div>
            </Tooltip>
          );
        }}
        renderTopToolbarCustomActions={() => {
          const generationIds = Object.keys(rowSelection);
          const filteredGenerationListItems = generationListItems?.filter(({ id }) =>
            generationIds.includes(id)
          );
          const hasGeneratingListItem = filteredGenerationListItems?.some((d) => isGenerating(d));

          return (
            <Flex gap="sm">
              <Button onClick={openCreation}>Generate a Post</Button>

              <Tooltip label="You cannot sync generating posts" disabled={!hasGeneratingListItem}>
                <div>
                  <Button
                    disabled={
                      !generationIds.length || !generationListItems || hasGeneratingListItem
                    }
                    onClick={() =>
                      generationListItems &&
                      openSyncModal({
                        generatedPosts: filteredGenerationListItems ?? [],
                      })
                    }
                  >
                    Sync All
                  </Button>
                </div>
              </Tooltip>
            </Flex>
          );
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            mantineTableHeadCellProps: {
              style: {
                width: '170px',
              },
            },
          },
        }}
      />
      <EasyPostGenerationModal opened={creationOpened} onClose={closeCreation} keepMounted />
    </>
  );
}

export const ROUTE_PATH = 'blog-generations';

export const routeConfig: RouteConfig = {
  path: ROUTE_PATH,
  label: 'Generated Blogs',
  Icon: IconWand,
  Component: GeneratedPosts,
  children: [blogGenerationRouteConfig],
};
