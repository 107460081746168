import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@mantine/core';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useStore } from 'react-redux';
import { Store } from 'redux';
import { MDBDataTable } from 'mdbreact';
import { RouteConfig } from '../../../../types/RouteConfig';
import { AppState } from '../../../../store';
import { IconPlus } from '@tabler/icons-react';
import AddKeywordInputs from '../keywordtracking/AddKeyword';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';


interface KeywordData {
  domain: string;
  keyword: string;
  inactive: boolean;
  rankMobile: number | string;
  rankDesktop: number | string;
  daystamp: string;
}

interface DataTableRow {
  domain: string;
  keyword: string;
  daystamp: string;
  rankMobile: string;
  rankDesktop: string;
  rankMobileYesterday: string;
  rankDesktopYesterday: string;
  rankMobile7Days: string;
  rankDesktop7Days: string;
  rankMobile30Days: string;
  rankDesktop30Days: string;
}

interface KeywordData {
  domain: string;
  keyword: string;
  rankMobile: number | string;
  rankDesktop: number | string;
  daystamp: string;
}

const formatRank = (rank: number | string | undefined | null): string => {
  console.log("rank")
  console.log(rank)
  if (rank === undefined || rank === null || rank === "-") {
    return "N/A";
  }
  return rank.toString();
};

const determineTrend = (today: number | string | undefined, yesterday: number | string | undefined) => {
  if (today === "N/A") {
    if (typeof yesterday === "number") {
      return <IconArrowDown color="red" />;
    }
    return "";
  }
  if (today === "N/A" || yesterday === "N/A") {
    return "";
  }
  today = Number(today);
  yesterday = Number(yesterday);
  if (today < yesterday) {
    return <IconArrowUp color="green" />;
  } else if (today > yesterday) {
    return <IconArrowDown color="red" />;
  }
  return "";
};




interface KeywordTableProps {
  data: KeywordData[];
  deleteKeyword: (keyword: string, domain: string) => Promise<void>;
  yesterdayData: KeywordData[];
  past7DaysData: KeywordData[];
  past30DaysData: KeywordData[];
}

const createKeywordMap = (data: KeywordData[]) => {
  const map = new Map<string, KeywordData>();
  data.forEach((item: KeywordData) => {
    if (item && item.keyword) {
      map.set(item.keyword, item);
    }
  });
  return map;
};

  function KeywordTable({ data, deleteKeyword, yesterdayData, past7DaysData, past30DaysData }: KeywordTableProps) {
    const columns = [
      {
        label: 'Domain',
        field: 'domain',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Keyword',
        field: 'keyword',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Last Crawl',
        field: 'daystamp',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Position Mobile (Today)',
        field: 'rankMobile',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Position Desktop (Today)',
        field: 'rankDesktop',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Position Mobile (Yesterday)',
        field: 'rankMobileYesterday',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Position Desktop (Yesterday)',
        field: 'rankDesktopYesterday',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Position Mobile (Past 7 Days)',
        field: 'rankMobile7Days',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Position Desktop (Past 7 Days)',
        field: 'rankDesktop7Days',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Position Mobile (Past 30 Days)',
        field: 'rankMobile30Days',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Position Desktop (Past 30 Days)',
        field: 'rankDesktop30Days',
        sort: 'asc',
        width: 200
      },
      {
        label: 'active',
        field: 'isActive',
        sort: 'asc',
        with: 30
      },
      {
        label: 'deactivate',
        field: 'delete',
        sort: 'asc',
        width: 50
      }
    ];
    const yesterdayMap = createKeywordMap(yesterdayData);
    const past7DaysMap = createKeywordMap(past7DaysData);
    const past30DaysMap = createKeywordMap(past30DaysData);
  
    const rows = data.map((entry, index) => {
      const yData = yesterdayMap.get(entry.keyword);
      const p7Data = past7DaysMap.get(entry.keyword);
      const p30Data = past30DaysMap.get(entry.keyword);


      const mobileToday = formatRank(entry.rankMobile);
      const desktopToday = formatRank(entry.rankDesktop);

      return {
        domain: entry.domain,
        keyword: entry.keyword,
        daystamp: entry.daystamp,
        inactive: entry.inactive,
        rankMobile: mobileToday,
        rankDesktop: desktopToday,
        rankMobileYesterday: (
          <>
            {formatRank(yData?.rankMobile)} {determineTrend(mobileToday, yData?.rankMobile)}
          </>
        ),
        rankDesktopYesterday: (
          <>
            {formatRank(yData?.rankDesktop)} {determineTrend(desktopToday, yData?.rankDesktop)}
          </>
        ),
        rankMobile7Days: (
          <>
            {formatRank(p7Data?.rankMobile)} {determineTrend(mobileToday, p7Data?.rankMobile)}
          </>
        ),
        rankDesktop7Days: (
          <>
            {formatRank(p7Data?.rankDesktop)} {determineTrend(desktopToday, p7Data?.rankDesktop)}
          </>
        ),
        rankMobile30Days: (
          <>
            {formatRank(p30Data?.rankMobile)} {determineTrend(mobileToday, p30Data?.rankMobile)}
          </>
        ),
        rankDesktop30Days: (
          <>
            {formatRank(p30Data?.rankDesktop)} {determineTrend(desktopToday, p30Data?.rankDesktop)}
          </>
        ),
        isActive: (
          <>
          {entry.inactive ? 'inactive' : 'active'}
          </>
        ),
        delete: (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button onClick={() => deleteKeyword(entry.keyword, entry.domain)} style={{borderRadius: "5px"}}>
            x
          </Button>
          </div>
        ),
      };
    });

  
    const datatableData = { columns, rows };
    return <MDBDataTable key={new Date().getTime()} striped bordered small data={datatableData} entries={20} />;
  }

export default function Tracking() {
  const [keywordData, setKeywordData] = useState<KeywordData[]>([]);
  const [yesterdayData, setYesterdayData] = useState<KeywordData[]>([]);
  const [past7DaysData, setPast7DaysData] = useState<KeywordData[]>([]);
  const [past30DaysData, setPast30DaysData] = useState<KeywordData[]>([]);
  const [updateCounter, setUpdateCounter] = useState(0); // This one is used to make sure the Table is rerendered on the callback updateData

  const store = useStore<AppState>();
  const headers: { [key: string]: string } = {
    'Content-Type': 'application/json',
    'X-CSRFToken': Cookies.get('csrftoken') ?? '',
  };

  if (store.getState().auth.token) {
    headers['Authorization'] = `Token ${store.getState().auth.token}`;
  }

  useEffect(() => {
    const fetchData = async () => {

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL!}/api/v1/keywords/list-keywords/`, { headers });
        setKeywordData(response.data.data.today);
        setYesterdayData(response.data.data.yesterday);
        setPast7DaysData(response.data.data.past7days);
        setPast30DaysData(response.data.data.past30days);
      } catch (error) {
        console.error('Error fetching keyword data', error);
      }
    };

    fetchData();
  }, [store, updateCounter]);

  const updateData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL!}/api/v1/keywords/list-keywords/`, { headers });
      // Deep copy the data for each state
      const freshKeywordData = JSON.parse(JSON.stringify(response.data.data.today));
      const freshYesterdayData = JSON.parse(JSON.stringify(response.data.data.yesterday));
      const freshPast7DaysData = JSON.parse(JSON.stringify(response.data.data.past7days));
      const freshPast30DaysData = JSON.parse(JSON.stringify(response.data.data.past30days));

      // Set the state with the deep copied data
      setKeywordData(freshKeywordData);
      setYesterdayData(freshYesterdayData);
      setPast7DaysData(freshPast7DaysData);
      setUpdateCounter(prev => prev + 1);
    } catch (error) {
      console.error('Error fetching keyword data', error);
    }
  };

  const deleteKeyword = async (keyword: string, domain: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL!}/api/v1/keywords/delete-keyword/`,
        { keyword, domain },
        { headers }
      );

      if (response.data.success) {
        updateData();  // Call this to refresh the table data
      } else {
        console.error('Deletion failed:', response.data.message);
      }
    } catch (error) {
      console.error('Error deleting keyword:', error);
    }
  };

  return (
    <Grid columns={24}>
      <AddKeywordInputs keyword={"yolo"} domain={"mam"} updateData={updateData}/><br/>
      <div style={{margin: "25px"}}>
      <h3>Deactivated keyword won't be considered to track anymore!</h3>
      </div>
      <Grid.Col span={24}>
        <KeywordTable 
          data={keywordData}
          deleteKeyword={deleteKeyword}
          yesterdayData={yesterdayData}
          past7DaysData={past7DaysData}
          past30DaysData={past30DaysData} 
        />
      </Grid.Col>
    </Grid>
  );
}

const ROUTE_PATH = 'tracking';

export const routeConfig: RouteConfig = {
    path: ROUTE_PATH,
    Icon: IconPlus,
    label: 'Tracking',
    Component: Tracking,
    hidden: false,
};
