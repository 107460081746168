// eslint-disable-next-line import/no-unresolved
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import { useGetUserQuery, useUpdateUserMutation } from '../store/user';
import { User } from '../model/User';

export const useUser = (queryOptions?: Partial<RefetchConfigOptions>) => {
  const { data: user, isLoading, error } = useGetUserQuery(undefined, queryOptions);
  const [updateUser, { isLoading: updateLoading, error: updateError }] = useUpdateUserMutation();

  const update = (u: User) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateUser(u);
  };

  return {
    user,
    isLoading: isLoading || updateLoading,
    error: error || updateError,
    update,
  };
};
