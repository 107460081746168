import { useForm } from '@mantine/form';
import {
  Box,
  Button,
  Container,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  ModalProps,
  PasswordInput,
  Select,
  Stack,
  TextInput,
  Tooltip,
} from '@mantine/core';
import React from 'react';
import { ContextModalProps } from '@mantine/modals';
import { IconInfoCircle } from '@tabler/icons-react';
import { validateRequired } from '../../../../validators';
import { useFormApiErrorHandler } from '../../../../hooks/useFormApiErrorHandler';
import ErrorText from '../../../../components/ErrorText';
import { InstanceType, WordPressInstance } from '../../model/Instance';
import { useAddInstanceMutation } from '../../store/instances/api';

type FormValues = Omit<WordPressInstance, 'id'>;

export const instanceTypeSelections = [{ label: 'WordPress', value: `${InstanceType.WORDPRESS}` }];

function InstanceCreationForm({ onClose }: { onClose: () => void }) {
  const [addInstance, { isLoading, error }] = useAddInstanceMutation();

  const form = useForm<FormValues>({
    initialValues: {
      type: InstanceType.WORDPRESS,
      name: '',
      host: '',
      username: '',
      password: '',
    },
    validate: {
      host: validateRequired('Host'),
      name: validateRequired('Name'),

      username: (val, values) => {
        if (values.type === InstanceType.WORDPRESS) {
          return validateRequired('Username')(val);
        }

        return undefined;
      },
      password: (val, values) => {
        if (values.type === InstanceType.WORDPRESS) {
          return validateRequired('Password')(val);
        }

        return undefined;
      },
    },
  });

  useFormApiErrorHandler(form, error);

  return (
    <>
      <LoadingOverlay visible={isLoading} overlayBlur={1} />

      <form
        onSubmit={form.onSubmit((values) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          addInstance({ ...values }).then((res) => {
            if (!('error' in res)) {
              form.reset();
              onClose();
            }
          });
        })}
        noValidate
      >
        <Stack>
          <Select
            required
            label="Instance type"
            data={instanceTypeSelections}
            {...form.getInputProps('type')}
          />

          <TextInput
            required
            label="Name"
            placeholder="My super instance 9000"
            {...form.getInputProps('name')}
          />

          <TextInput
            required
            label="Host"
            placeholder="https://www.wordgenie.de"
            {...form.getInputProps('host')}
          />

          <TextInput
            required
            label="Username"
            description={
              <Flex align="center">
                <a href={'https://www.youtube.com/watch?v=bsz6hb1EUMY&ab_channel=StrangerStudios'}>
                  Create credentials
                </a>
                <Tooltip multiline label={`create an application password`}>
                  <Flex ml={4}>
                    <IconInfoCircle size={16} />
                  </Flex>
                </Tooltip>
              </Flex>
            }
            placeholder="max@mustermann.de"
            autoComplete="off"
            {...form.getInputProps('username')}
          />

          <PasswordInput
            required
            label="Password"
            placeholder="Your password"
            autoComplete="off"
            {...form.getInputProps('password')}
          />

          {form.errors.nonFieldErrors && <ErrorText>{form.errors.nonFieldErrors}</ErrorText>}

          <Group position="right">
            <Button variant="default" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Create Instance</Button>
          </Group>
        </Stack>
      </form>
    </>
  );
}

export default function InstanceCreationModal({ onClose, ...props }: ModalProps) {
  return (
    <Modal withCloseButton onClose={onClose} title="Create a new instance" centered {...props}>
      <InstanceCreationForm onClose={onClose} />
    </Modal>
  );
}

export function InstanceCreationContextModal({ context, id }: ContextModalProps) {
  return <InstanceCreationForm onClose={() => context.closeModal(id)} />;
}
