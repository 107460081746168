import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { selectToken } from '../auth';
import { AppState } from '../store';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL!}/api/v1/`,
  prepareHeaders: (headers, { getState }) => {
    const token = selectToken(getState() as AppState);
    if (token) {
      headers.set('authorization', `Token ${token}`);
    }
    headers.set('X-CSRFToken', Cookies.get('csrftoken') ?? '');

    return headers;
  },
});

export const baseApi = createApi({
  reducerPath: 'backendApi',
  baseQuery,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: ['User', 'Instance', 'BlogPost', 'GeneratedPost', 'Announcement'],
  endpoints: () => ({}),
});
