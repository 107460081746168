import { baseApi } from '../../../../store/api';
import { Instance } from '../../model/Instance';
import { BlogPost } from '../../model/BlogPost';

export const api = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getBlogPosts: builder.query<BlogPost[], void>({
      query: () => 'instances/posts/',
      providesTags: [{ type: 'BlogPost', id: 'LIST' }],
    }),

    getBlogPost: builder.query<BlogPost, { instanceId: string; postId: string }>({
      query: ({ instanceId, postId }) => `instances/posts/${instanceId}/${postId}`,
      providesTags: (_, __, { instanceId, postId }) => [
        { type: 'BlogPost', id: `${instanceId}/${postId}` },
      ],
    }),

    addBlogPost: builder.mutation({
      query: (instance: Instance) => ({
        url: 'blogposts/',
        method: 'POST',
        body: instance,
      }),
      invalidatesTags: [{ type: 'BlogPost', id: 'LIST' }],
    }),

    updateBlogPost: builder.mutation({
      query: (instance: Instance) => ({
        url: 'blogposts/',
        method: 'POST',
        body: instance,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: 'BlogPost', id },
        { type: 'BlogPost', id: 'LIST' },
      ],
    }),

    deleteBlogPost: builder.mutation({
      query: (instance: Instance) => ({
        url: 'blogposts/',
        method: 'DELETE',
        body: instance,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: 'BlogPost', id },
        { type: 'BlogPost', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetBlogPostQuery,
  useGetBlogPostsQuery,
  useAddBlogPostMutation,
  useDeleteBlogPostMutation,
  useUpdateBlogPostMutation,
} = api;
