import { useForm } from '@mantine/form';
import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  ModalProps,
  Stack,
  Textarea,
  Text,
} from '@mantine/core';
import React from 'react';
import { ContextModalProps } from '@mantine/modals';
import { validateRequired } from '../../../../validators';
import { useFormApiErrorHandler } from '../../../../hooks/useFormApiErrorHandler';
import ErrorText from '../../../../components/ErrorText';
import { Feedback } from '../../model/Feedback';
import { useAddFeedbackMutation } from '../../../../store/user';

function FeedbackCreationForm({ onClose }: { onClose: () => void }) {
  const [addFeedback, { isLoading, error }] = useAddFeedbackMutation();

  const form = useForm<Feedback>({
    initialValues: {
      feedback: '',
    },
    validate: {
      feedback: validateRequired('Feedback'),
    },
  });

  useFormApiErrorHandler(form, error);

  return (
    <>
      <LoadingOverlay visible={isLoading} overlayBlur={1} />

      <form
        onSubmit={form.onSubmit((values) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          addFeedback({ ...values }).then((res) => {
            if (!('error' in res)) {
              form.reset();
              onClose();
            }
          });
        })}
        noValidate
      >
        <Stack>
          <Text>Send us your feedback and let us know where we can improve.</Text>

          <Textarea
            required
            placeholder="Your feedback"
            minRows={6}
            {...form.getInputProps('feedback')}
          />

          {form.errors.nonFieldErrors && <ErrorText>{form.errors.nonFieldErrors}</ErrorText>}

          <Group position="right">
            <Button variant="default" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Send Feedback</Button>
          </Group>
        </Stack>
      </form>
    </>
  );
}

export default function FeedbackModal({ onClose, ...props }: ModalProps) {
  return (
    <Modal withCloseButton onClose={onClose} title="Create a new instance" centered {...props}>
      <FeedbackCreationForm onClose={onClose} />
    </Modal>
  );
}

export function FeedbackContextModal({ context, id }: ContextModalProps) {
  return <FeedbackCreationForm onClose={() => context.closeModal(id)} />;
}
