import React, { ReactNode, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { AppShell, useMantineTheme } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import Announcements from '../../components/Announcements';
import { routeConfig as profileRoute } from './pages/profile/Profile';
// import { routeConfig as dashboardRoute } from './pages/getting-started/GettingStarted';
import { routeConfig as blogPostsRoute } from './pages/blog-posts/BlogPosts';
import { routeConfig as instancesRoute } from './pages/instances/Instances';
import { routeConfig as trackingRoute } from './pages/keywordtracking/Keywordtracking';
import { routeConfig as apiRoute } from './pages/api/ApiPage';

import Navbar from './components/Navbar';
import Header from './components/Header';
import { RouteConfig } from '../../types/RouteConfig';
import ErrorBoundary from '../../components/ErrorBoundary';
import SynchronisationContextModal from './components/modal/SynchronisationContextModal';
import { InstanceCreationContextModal } from './components/modal/InstanceCreationModal';
import { FeedbackContextModal } from './components/modal/FeedbackModal';

const getRoutesFromConfig = (configs: RouteConfig[]): ReactNode[] => {
  return configs.map(({ path, children, Component }) => {
    const hasChildrenAndComponent = !!(children && Component);

    if (hasChildrenAndComponent) {
      return (
        <>
          <Route key={`${path}route`} path={path} element={<Component />} />
          {children &&
            getRoutesFromConfig(
              children.map((child) => ({ ...child, path: `${path}/${child.path}` }))
            )}
        </>
      );
    }

    return (
      <Route key={path} path={path} element={Component && <Component />}>
        {children && getRoutesFromConfig(children)}
      </Route>
    );
  });
};

const routes: RouteConfig[] = [blogPostsRoute, profileRoute, instancesRoute, trackingRoute, apiRoute];

// const routes: RouteConfig[] = [dashboardRoute, blogPostsRoute, profileRoute, instancesRoute, trackingRoute, apiRoute];

function AppWrapper({ children }: { children: React.ReactNode }) {
  const theme = useMantineTheme();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  // Close the navbar when the location changes. This is useful when the user clicks on a link.
  useEffect(() => {
    setOpen(false);
  }, [location, setOpen]);

  return (
    <AppShell
      styles={{
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          maxHeight: '100%',
          maxWidth: '100%',
        },
      }}
      navbarOffsetBreakpoint="sm"
      navbar={<Navbar open={open} items={routes} />}
      header={
        <Header
          open={open}
          onOpenToggle={() => {
            setOpen((o) => !o);
          }}
        />
      }
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </AppShell>
  );
}

const modals = {
  feedback: FeedbackContextModal,
  synchronisation: SynchronisationContextModal,
  instanceCreation: InstanceCreationContextModal,
};

declare module '@mantine/modals' {
  export interface MantineModalsOverride {
    modals: typeof modals;
  }
}

export default function NestedApp() {
  return (
    <ModalsProvider modals={modals}>
      <Announcements />

      <AppWrapper>
        <Routes>
          {getRoutesFromConfig(routes)}
          <Route
            path="/"
            element={<Navigate to={`${profileRoute.path}`} />}
          />
        </Routes>
      </AppWrapper>
    </ModalsProvider>
  );
}
