import { useForm } from '@mantine/form';
import React from 'react';
import { Button, LoadingOverlay, PasswordInput, Stack, TextInput } from '@mantine/core';
import { useAuth } from '../../../hooks/useAuth';
import { validateEmail, validatePassword } from '../../../validators';
import { useFormApiErrorHandler } from '../../../hooks/useFormApiErrorHandler';
import ErrorText from '../../../components/ErrorText';

interface LoginFormValues {
  email: string;
  password: string;
}

export default function LoginForm() {
  const { login, isLoading, error } = useAuth();

  const form = useForm<LoginFormValues>({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: validateEmail,
      password: validatePassword,
    },
  });

  useFormApiErrorHandler(form, error);

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        login(values.email, values.password);
      })}
      noValidate
    >
      <LoadingOverlay visible={isLoading} overlayBlur={1} />

      <Stack>
        <TextInput
          required
          label="Email"
          placeholder="max@mustermann.de"
          autoComplete="username"
          {...form.getInputProps('email')}
        />

        <PasswordInput
          required
          autoComplete="current-password"
          label="Password"
          placeholder="Your password"
          {...form.getInputProps('password')}
        />

        {form.errors.nonFieldErrors && <ErrorText>{form.errors.nonFieldErrors}</ErrorText>}

        <Button type="submit">Login</Button>
      </Stack>
    </form>
  );
}
