import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store';
import { useAuth } from '../../../../hooks/useAuth';
import { Button } from '@mantine/core'; // Import your button component
import Cookies from 'js-cookie';

const CancelSubscription = () => {
    const selectToken = (state: AppState) => state.auth.token;
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const csrfToken = Cookies.get('csrftoken') ?? '';
    const { logout } = useAuth();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (window.confirm("Are you sure you want to cancel your account?")) {
            const headers: Record<string, string> = {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
            };

            if (token) {
                headers['authorization'] = `Token ${token}`;
            }

            fetch(`${process.env.REACT_APP_API_URL}/api/v1/api/cancel-subscription/`, {
                method: 'POST',
                headers: headers,
            })
                .then(response => {
                    if (!response.ok) throw new Error('Network error');
                    return response.json();
                })
                .then(data => {
                    if (data.url) {
                        window.location.href = data.url;
                    }
                })
                .catch(error => {
                    // Handle errors
                });
        }
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <form onSubmit={handleSubmit}>
                <Button
                    component="button"
                    size="md"
                    type="submit"
                    color="blue"
                    radius="xl"
                >
                    ❌ Cancel your subscription ❌
                </Button>
            </form>
        </div>
    );
};

export default CancelSubscription;
