import React, { ReactNode } from 'react';
import { Badge, Center, Flex, Loader, Tooltip } from '@mantine/core';
import { IconChecklist, IconExclamationCircle, IconListCheck } from '@tabler/icons-react';
import { GenerationStatus } from '../model/GeneratedPost';

interface GenerationStatusProps {
  generationStatus: GenerationStatus;
}

const statusMap: Record<
  GenerationStatus,
  {
    text: string;
    color?: string;
    icon: ReactNode;
  }
> = {
  [GenerationStatus.GENERATING_BLOG]: {
    text: 'Generating',
    icon: <Loader variant="dots" size="sm" />,
  },
  [GenerationStatus.GENERATING_TOC]: {
    text: 'Generating',
    icon: <Loader variant="dots" size="sm" />,
  },
  [GenerationStatus.FAILED]: {
    text: 'Failed',
    color: 'red',
    icon: <IconExclamationCircle color="red" />,
  },
  [GenerationStatus.GENERATED_BLOG]: {
    text: 'Blog generated',
    color: 'green',
    icon: <IconChecklist />,
  },
  [GenerationStatus.GENERATED_TOC]: {
    text: 'TOC generated',
    color: 'green',
    icon: <IconListCheck />,
  },
};

export default function GenerationStatusIcon({ generationStatus }: GenerationStatusProps) {
  const { text, color, icon } = statusMap[generationStatus];

  return (
    <Flex gap="sm" align="center" justify="left">
      <Tooltip label={generationStatus}>
        <Center>{icon}</Center>
      </Tooltip>
      <Badge variant="filled" size="xs" color={color}>
        {text}
      </Badge>
    </Flex>
  );
}
