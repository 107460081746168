export enum GenerationStatus {
  GENERATING_TOC = 'generating_toc',
  GENERATED_TOC = 'generated_toc',
  GENERATING_BLOG = 'generating_blog',
  GENERATED_BLOG = 'generated_blog',
  FAILED = 'failed',
}

export enum WordpressStatus {
  PUBLISH = 'publish',
  DRAFT = 'draft',
  FUTURE = 'future',
  PENDING = 'pending',
  PRIVATE = 'private',
  TRASH = 'trash',
}

export interface Synchronisation {
  instance?: string;
  status?: string;
  isSynced?: boolean;
  syncedAt?: string;
  externalPostId?: string;
}

export interface GeneratedPost {
  id: string;
  modified: string;
  created: string;
  status: GenerationStatus;
  rating: number;
  topic: string;
  htmlContent?: string;
  tableOfContents?: string;
  synchronisation?: Synchronisation;
}

export type GeneratedPostListItem = Omit<GeneratedPost, 'htmlContent' | 'tableOfContents'>;

export function isGenerating(post: GeneratedPost | GeneratedPostListItem): boolean {
  return (
    post.status === GenerationStatus.GENERATING_TOC ||
    post.status === GenerationStatus.GENERATING_BLOG
  );
}
