import { useForm } from '@mantine/form';
import {
  Button,
  Group,
  LoadingOverlay,
  ModalProps,
  PasswordInput,
  Select,
  Stack,
  TextInput,
} from '@mantine/core';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFormApiErrorHandler } from '../../../../../hooks/useFormApiErrorHandler';
import { InstanceType, WordPressInstance } from '../../../model/Instance';
import {
  useDeleteInstanceMutation,
  useGetInstanceQuery,
  useUpdateInstanceMutation,
} from '../../../store/instances/api';
import { validateRequired } from '../../../../../validators';
import DetailedView from '../../../components/DetailedView';
import ErrorText from '../../../../../components/ErrorText';
import { RouteConfig } from '../../../../../types/RouteConfig';

export default function Instance() {
  const { id } = useParams() as { id: string };

  const { data: instance, isLoading, error } = useGetInstanceQuery(id);
  const [updateInstance, { isLoading: isUpdating, error: updateError }] =
    useUpdateInstanceMutation();
  const [deleteInstance, { isLoading: isDeleting, error: deleteError }] =
    useDeleteInstanceMutation();

  const form = useForm<WordPressInstance>({
    validate: {
      host: validateRequired('Host'),
      name: validateRequired('Name'),

      username: (val, values) => {
        if (values.type === InstanceType.WORDPRESS) {
          return validateRequired('Username')(val);
        }

        return undefined;
      },
      password: (val, values) => {
        if (values.type === InstanceType.WORDPRESS) {
          return validateRequired('Password')(val);
        }

        return undefined;
      },
    },
  });

  useFormApiErrorHandler(form, error ?? updateError ?? deleteError);

  const onSave = () =>
    form.onSubmit((values) => {
      updateInstance({ ...instance, ...values });
    })();

  const onDelete = () => {
    deleteInstance(id);
  };

  useEffect(() => {
    if (instance && !form.isDirty()) {
      form.setValues(instance);
    }
  }, [instance]);

  return (
    <form noValidate>
      <DetailedView
        title={instance?.name}
        onDelete={onDelete}
        onSave={onSave}
        isLoading={isLoading || isDeleting || isUpdating}
      >
        {({ disabled }) => (
          <Stack>
            <TextInput label="Instance type" disabled {...form.getInputProps('type')} />

            <TextInput
              required
              disabled={disabled}
              label="Name"
              placeholder="My super instance 9000"
              {...form.getInputProps('name')}
            />

            <TextInput
              required
              disabled={disabled}
              label="Host"
              placeholder="https://www.wordgenie.de"
              {...form.getInputProps('host')}
            />

            <TextInput
              required
              disabled={disabled}
              label="Username"
              placeholder="max@mustermann.de"
              autoComplete="off"
              {...form.getInputProps('username')}
            />

            <PasswordInput
              required
              disabled={disabled}
              label="Password"
              placeholder="Your password"
              autoComplete="off"
              {...form.getInputProps('password')}
            />

            {form.errors.nonFieldErrors && <ErrorText>{form.errors.nonFieldErrors}</ErrorText>}
          </Stack>
        )}
      </DetailedView>
    </form>
  );
}

export const routeConfig: RouteConfig = {
  path: ':id',
  Component: Instance,
  label: 'Instance',
  hidden: true,
};
