export enum WritingTone {
  FORMAL = 'Formal',
  INFORMAL = 'Informal',
  OPTIMISTIC = 'Optimistic',
  WORRIED = 'Worried',
  PROFESSIONAL = 'Professional',
  CURIOUS = 'Curious',
}

export enum Languages {
  GERMAN = 'Deutsch',
  ENGLISH = 'English',
  SPANISH = 'Español',
  FRENCH = 'Français',
  DUTCH = 'Dutch',
  PORTUGUESE = 'Portuguese',
  POLISH = 'Polish',
  ITALIAN = 'Italian',
}

export enum BlogLength {
  SHORT = 'Short',
  MEDIUM = 'Medium',
  LONG = 'Long',
}

export interface EasyGeneratePost {
  topic: string;
  product?: string;
  targetAudience?: string;
  context?: string;
  blogLength: BlogLength;
  language: Languages;
  picture?: boolean;
}
