import React, { ReactNode } from 'react';
import { Text, TextProps } from '@mantine/core';

interface ErrorTextProps extends TextProps {
  children: ReactNode;
}

function ErrorText({ children, size, ...rest }: ErrorTextProps) {
  return (
    <Text color="red" size={size ?? 'sm'} {...rest}>
      {children}
    </Text>
  );
}

export default ErrorText;
