import { Button, ButtonProps } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconPlus } from '@tabler/icons-react';
import React from 'react';

export default function FeedbackButton(props: ButtonProps) {
  return (
    <Button
      mb="xl"
      leftIcon={<IconPlus />}
      variant="outline"
      fullWidth
      onClick={() => {
        modals.openContextModal({
          modal: 'feedback',
          title: 'Let us know what you think!',
          innerProps: undefined,
        });
      }}
    >
      Feedback
    </Button>
  );
}
