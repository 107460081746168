import React, { FC, useEffect, useState } from 'react';
import {AppState} from "../../../../store";
import Cookies from "js-cookie";
import axios from "axios";
import { Button, Checkbox, Loader } from '@mantine/core';
import { Store } from 'redux';
import {useStore} from "react-redux";
import {notifications} from "@mantine/notifications";
import { showNotification } from '@mantine/notifications';


interface TitleProps {
  title: string;
  subtitle: string;
  topic: string;
  language: string;
  onClose: () => void;
}

const selectToken = (state: AppState) => state.auth.token;
const refresh = () => window.location.reload();

const TopicalContent: FC<TitleProps> = ({ title, subtitle, topic, language, onClose}) => {
  const [data, setData] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const store = useStore() as Store<AppState>;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const state: AppState = store.getState(); // Get the current state
        const token = selectToken(state);
        const csrfToken = Cookies.get('csrftoken') ?? '';

        const headers: Record<string, string> = {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
        };

        if (token) {
            headers['authorization'] = `Token ${token}`;
        }

        const response = await axios.post(`${process.env.REACT_APP_API_URL!}/api/v1/api/topical/`, { topic: topic, language: language }, { headers });
        if (response.status !== 200) {
          notifications.show({ title: 'Error', message: 'Something went wrong!', color: 'red' })
          notifications.show({ title: 'Error', message: 'Buy token or use your API key in your Profile.', color: 'red' })
        }
        setData(response.data.topicals);
      } catch (error) {
        notifications.show({ title: 'Error', message: 'Something went wrong!', color: 'red' })
        notifications.show({ title: 'Error', message: 'Buy token or use your API key in your Profile.', color: 'red' })
        // sleep 2 seconds to make sure user understands error
        await new Promise(r => setTimeout(r, 2000));
        window.location.href = '/wg/profile/'; // Replace '/custom-route' with the desired route you want to redirect the user to
        console.error(error);
      }
    };
    fetchData();
  }, [topic]);

  const handleSaveSelection = async () => {
      try {
        const state: AppState = store.getState(); // Get the current state
        const token = selectToken(state);
        const csrfToken = Cookies.get('csrftoken') ?? '';

        const headers: Record<string, string> = {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
        };

        if (token) {
            headers['authorization'] = `Token ${token}`;
        }

        const response = await axios.post(`${process.env.REACT_APP_API_URL!}/api/v1/generations/generate-bulk/`, { language: language, topics: selectedItems }, { headers });
        onClose();
        refresh();
        const event = new KeyboardEvent('keydown', { key: 'Escape' });
        document.dispatchEvent(event);
    } catch (error) {
      showNotification({
        title: 'Something went wrong',
        message: 'Make sure you have setup an API-Key or enough tokens to generate a post!',
      });
      console.error(error);
    }
  };

  const handleToggleSelection = (item: string) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  return (
    <>
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
      {data.length === 0 && <p><Loader size="xl" variant="dots" /></p>}
      {data.length > 0 && (
        <ul>
          {data.map((item, index) => (
              <Checkbox checked={selectedItems.includes(item)} label={item} onChange={() => handleToggleSelection(item)} />
          ))}
        </ul>
      )}
      <Button disabled={data.length === 0} onClick={handleSaveSelection}>Generate</Button>
    </>
  );
};

export default TopicalContent;
