import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react';
import { Box, LoadingOverlay } from '@mantine/core';
import { useSiteSettings } from '../../../hooks/useSiteSettings';

interface BlogEditorProps {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

const getEditorElements = (editorId?: string) => {
  let elements = document.getElementsByClassName('tox-editor-header');
  const header = elements.length ? elements[0] : undefined;

  elements = header?.getElementsByClassName('tox-menubar') ?? new HTMLCollection();
  const fileHeader = elements.length ? elements[0] : undefined;

  elements = header?.getElementsByClassName('tox-toolbar') ?? new HTMLCollection();
  const toolbar = elements.length ? elements[0] : undefined;

  const input = editorId ? document.getElementById(editorId) : null;

  return { fileHeader, toolbar, input };
};

export default function BlogEditor({ value, onChange, disabled }: BlogEditorProps) {
  const { isDarkMode } = useSiteSettings();
  const [loading, setLoading] = useState(true);

  return (
    <Box
      pos="relative"
      sx={{
        height: 600,
      }}
    >
      <LoadingOverlay visible={loading} />
      <GrammarlyEditorPlugin clientId="client_DACVHowGJs5ZYYDn6qENse">
        <Editor
          apiKey="hh4cgk6qj9anl7evpio1oeu4s29peph4g08tbu5gwfodweid"
          value={value}
          disabled={disabled}
          onEditorChange={onChange}
          onInit={() => setLoading(false)}
          plugins={[
            'advlist autolink lists link image',
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount',
            'fullscreen',
          ]}
          init={{
            height: 600,
            menubar: false,
            file_picker_types: 'export',
            plugins:
              'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
            toolbar:
              ' fullscreen preview | undo redo | code | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | print | insertfile image media template link anchor codesample | ltr rtl | bold italic underline strikethrough ',
            content_style:
              "body { font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; font-size: 14px }",
            branding: false,
            statusbar: false,
            skin: isDarkMode ? 'oxide-dark' : 'oxide',
            content_css: isDarkMode ? 'dark' : 'default',
            quickbars_selection_toolbar:
              'bold italic underline strikethrough | h1 h2 h3 blockquote code | quicklink quickimage quicktable',
            noneditable_class: 'mceNonEditable',
            toolbar_mode: 'sliding',
            contextmenu: 'link image table',
          }}
        />
      </GrammarlyEditorPlugin>
    </Box>
  );
}
