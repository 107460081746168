import { baseApi } from '../../../../store/api';
import { GeneratedPost, GeneratedPostListItem } from '../../model/GeneratedPost';
import { EasyGeneratePost } from '../../model/EasyGeneratePost';

export const api = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getGeneratedPosts: builder.query<GeneratedPostListItem[], void>({
      query: () => 'generations/',
      providesTags: [{ type: 'GeneratedPost', id: 'LIST' }],
    }),

    getGeneratedPost: builder.query<GeneratedPost, string>({
      query: (id: string) => `generations/${id}/`,
      providesTags: (_, __, id) => [{ type: 'GeneratedPost', id }],
    }),

    updateGeneratedPost: builder.mutation({
      query: (generatedPost: Partial<GeneratedPost> & Pick<GeneratedPost, 'id'>) => ({
        url: `generations/${generatedPost.id}/`,
        method: 'PATCH',
        body: generatedPost,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: 'GeneratedPost', id },
        { type: 'GeneratedPost', id: 'LIST' },
      ],
    }),

    deleteGeneratedPost: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `generations/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, id) => [
        { type: 'GeneratedPost', id },
        { type: 'GeneratedPost', id: 'LIST' },
      ],
    }),

    easyGeneratePost: builder.mutation<string, EasyGeneratePost>({
      query: (easyGeneratePostRequest) => ({
        url: `generations/easy-generate/`,
        method: 'POST',
        body: easyGeneratePostRequest,
      }),
      invalidatesTags: (id) => [
        { type: 'GeneratedPost', id },
        { type: 'GeneratedPost', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetGeneratedPostQuery,
  useGetGeneratedPostsQuery,
  useEasyGeneratePostMutation,
  useDeleteGeneratedPostMutation,
  useUpdateGeneratedPostMutation,
} = api;
