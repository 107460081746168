import {
  Burger,
  Flex,
  Header as MantineHeader,
  MediaQuery,
  Title,
  useMantineTheme,
  Box,
} from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { DarkModeToggle } from './DarkModeToggle';

interface HeaderProps {
  open: boolean;
  onOpenToggle: () => void;
}

export default function Header({ open, onOpenToggle }: HeaderProps) {
  const theme = useMantineTheme();

  return (
    <MantineHeader height={{ base: 70 }} p="md">
      <Flex align="center" gap="sm">
        <MediaQuery largerThan="md" styles={{ display: 'none' }}>
          <Burger opened={open} onClick={onOpenToggle} size="sm" color={theme.colors.gray[6]} />
        </MediaQuery>

        <MediaQuery smallerThan="md" styles={{ marginLeft: 'auto' }}>
          <Flex mr="auto" align="center" justify="center">
            <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit' }}>
              <Title style={{ margin: 0 }}>WordGenie</Title>
            </Link>
          </Flex>
        </MediaQuery>

        <DarkModeToggle />
      </Flex>
    </MantineHeader>
  );
}
