import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { Card, Text, Button, Overlay } from '@mantine/core';
import styles from './LifeTimePlan.module.css';
import { AppState } from '../../../../store';  // Adjust the import path as needed

export function LifeTimePlan() {
  const dispatch = useDispatch();
  const token = useSelector((state: AppState) => state.auth.token);
  const csrfToken = Cookies.get('csrftoken') ?? '';

  const createSubscription = (event: any) => {
    event.preventDefault();  

    const headers: any = {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
    };

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    fetch(`${process.env.REACT_APP_API_URL}/api/v1/api/lifetime-deal/`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        // Here you might want to add actual form data, currently using an empty object
      }),
    })
    .then(response => {
      if (!response.ok) throw new Error('Network error');
      return response.json();
    })
    .then(data => {
      if (data.url) {
        window.location.href = data.url;  // Redirect if URL is returned
      }
    })
    .catch(error => {
      console.error('Failed to create subscription:', error);
    });
  };

  return (
    <Card radius="md" style={{ position: 'relative', overflow: 'hidden' }}>
      <Overlay opacity={0.55} zIndex={0} />

      <div className={styles.container}>
        <Text size="lg" weight={700} style={{ color: 'black' }}>
          Unlock Your Content’s Potential - Just 49 €
        </Text>

        <Text size="m" style={{ color: 'black' }}>
          Get unlimited access to our app and API, crafting endless, top-quality, SEO-friendly blog posts using your own OpenAI-API-Keys. Perfect for those eager to consistently lead and innovate in their market.
        </Text>

        <Button variant="black" color="dark" size="s" className={styles.customButton} onClick={createSubscription}>
          Secure Your Lifetime Deal Now
        </Button>
      </div>
    </Card>
  );
}

export default LifeTimePlan;
