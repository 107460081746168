import { Button, createStyles, Divider, Navbar as MantineNavbar, Stack , NavLink} from '@mantine/core';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconPlus, IconShoppingCart} from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { NavbarItem } from './NavbarItem';
import UserButton from './UserButton';
import { RouteConfig } from '../../../types/RouteConfig';
import EasyPostGenerationModal from './modal/EasyPostGenerationModal';
import FeedbackButton from './FeedbackButton';
import { IconGauge, IconFingerprint } from '@tabler/icons-react';
import CheckoutForm from "../pages/profile/BuyStripe";

const useStyles = createStyles((theme) => ({
  footer: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },
}));

interface CustomNavbarProps {
  open: boolean;
  items: RouteConfig[];
}

export default function Navbar({ open, items }: CustomNavbarProps) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);

  const navbarItems = useMemo(
    () => items.map((item) => <NavbarItem key={`${item.label}navbar`} {...item} />),
    [items]
  );

  return (
    <>
      <MantineNavbar p="md" hiddenBreakpoint="sm" hidden={!open} width={{ sm: 250 }}>
        <MantineNavbar.Section grow>
          <Stack>{navbarItems}</Stack>
        </MantineNavbar.Section>


        <MantineNavbar.Section>
          {/*<Button*/}
          {/*  mb="md"*/}
          {/*  leftIcon={<IconCash />}*/}
          {/*  variant="outline"*/}
          {/*  fullWidth*/}
          {/*  onClick={() => {*/}
          {/*    ProductDisplay();*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Buy token*/}
          {/*</Button>*/}
          <CheckoutForm></CheckoutForm>
          <br></br>

          <Button
            mb="md"
            leftIcon={<IconPlus />}
            variant="outline"
            fullWidth
            onClick={() => {
              openModal();
            }}
          >
            Generate a Post
          </Button>

          <FeedbackButton />

        </MantineNavbar.Section>

        <MantineNavbar.Section className={classes.footer}>
          <Divider />
          <UserButton
            onClick={() => {
              navigate('profile');
            }}
          />
        </MantineNavbar.Section>
        <NavLink label="Important information & FAQ"
                 icon={<IconFingerprint size="1rem" stroke={1.5} />}
                 childrenOffset={28}
          >
          <NavLink label="FAQ's" component="a" href="https://wordgenie.de/app-faqs/" target="_blank"/>

          <NavLink label="Terms of Use & Privacy Policy" component="a" href="https://wordgenie.de/privacy-policy/" target="_blank"/>
            {/*<NavLink label="Second child link" />*/}
            {/*<NavLink label="Third child link" />*/}
          </NavLink>
      </MantineNavbar>

      <EasyPostGenerationModal opened={modalOpened} onClose={closeModal} />
    </>
  );
}
