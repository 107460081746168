import React from 'react';
// eslint-disable-next-line camelcase
import { IconArticle } from '@tabler/icons-react';
import { RouteConfig } from '../../../../types/RouteConfig';
import { routeConfig as blogGenerationsRouteConfig } from './pages/generated-posts/GeneratedPosts';
import { routeConfig as allBlogsRouteConfig } from './pages/instance-posts/InstancePosts';

export const ROUTE_PATH = 'blog-posts';

export const routeConfig: RouteConfig = {
  path: ROUTE_PATH,
  label: 'Blog Posts',
  Icon: IconArticle,
  children: [blogGenerationsRouteConfig, allBlogsRouteConfig],
};
