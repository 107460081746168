import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { Button, Grid, LoadingOverlay, Overlay, Textarea, TextInput, Tooltip } from '@mantine/core';
import BlogEditor from '../../../../../components/BlogEditor';
import { RouteConfig } from '../../../../../../../types/RouteConfig';
import {
  useDeleteGeneratedPostMutation,
  useGetGeneratedPostQuery,
  useUpdateGeneratedPostMutation,
} from '../../../../../store/generated-posts/api';
import DetailedView from '../../../../../components/DetailedView';
import {
  GeneratedPost as GeneratedPostType,
  GenerationStatus,
  isGenerating,
} from '../../../../../model/GeneratedPost';
import { useFormApiErrorHandler } from '../../../../../../../hooks/useFormApiErrorHandler';
import InstanceSelect from '../../../../../components/InstanceSelect';
import WordpressStatusSelect from '../../../../../components/WordpressStatusSelect';
import GenerationStatusIcon from '../../../../../components/GenerationStatusIcon';
import RatePost from '../../../../../components/RatePost';

export default function GeneratedPost() {
  const { id } = useParams() as { id: string };
  const form = useForm<GeneratedPostType>();
  const [hasGenerating, setHasGenerating] = useState(false);


  const {
    data: generatedPost,
    isLoading,
    error: fetchError,
  } = useGetGeneratedPostQuery(id, {
    pollingInterval: hasGenerating ? 5000 : undefined,
    refetchOnMountOrArgChange: true,
  });
  const [updateGeneratedPost, { isLoading: isUpdating, error: updateError, isSuccess: isUpdated }] =
    useUpdateGeneratedPostMutation();
  const [deleteGeneratedPost, { isLoading: isDeleting, error: deleteError }] =
    useDeleteGeneratedPostMutation();

  const error = fetchError ?? updateError ?? deleteError;

  useEffect(() => {
    if (generatedPost) {
      form.setValues({
        ...generatedPost,
        synchronisation: { ...generatedPost.synchronisation } ?? {},
      });
    }
  }, [generatedPost]);

  // TODO: remove when websocket is implemented
  useEffect(() => {
    const generating = !!generatedPost && isGenerating(generatedPost);
    setHasGenerating(generating);
  }, [generatedPost, setHasGenerating]);

  useFormApiErrorHandler(form, error);

  const onSave = () =>
    form.onSubmit((values) => {
      updateGeneratedPost({
        id: generatedPost!.id,
        topic: values.topic,
        htmlContent: values.htmlContent,
        synchronisation: {
          instance: values.synchronisation?.instance,
          status: values.synchronisation?.status,
        },
      });
    })();

  const onDelete = () => {
    deleteGeneratedPost(id);
  };

  const generating = !!generatedPost && isGenerating(generatedPost);
  console.log(generatedPost);
  return (
    <form>
      <DetailedView
        title={generatedPost?.topic}
        needsRedirect={error && 'status' in error && error.status === 404}
        isLoading={isLoading || isUpdating || isDeleting}
        isSaved={isUpdated}
        onSave={onSave}
        onDelete={onDelete}
        disableEditMode={generating}
      >
        {({ disabled }) => (
          <>
            {generatedPost && generatedPost.status === GenerationStatus.FAILED && (
              <Overlay center blur={15}>
                <GenerationStatusIcon generationStatus={generatedPost?.status} />
              </Overlay>
            )}

            <Tooltip label="Generating...">
              <LoadingOverlay
                zIndex={3}
                loaderProps={{ variant: 'dots' }}
                overlayBlur={15}
                visible={generating}
              />
            </Tooltip>

            <Grid gutter="md">
              <Grid.Col span={12}>
                <TextInput
                  label="Topic"
                  disabled={disabled}
                  value={form.values.topic}
                  onChange={(event) => form.setFieldValue('topic', event.currentTarget.value)}
                />
              </Grid.Col>

              <Grid.Col span={12}>
                <BlogEditor disabled={disabled} {...form.getInputProps('htmlContent')} />
              </Grid.Col>

              {generatedPost && 'rating' in generatedPost && (
                <Grid.Col span={12}>
                  <RatePost rating={generatedPost.rating} id={id} />
                </Grid.Col>
              )}

              <Grid.Col span={6}>
                <InstanceSelect
                  readOnly={disabled}
                  disabled={disabled}
                  {...form.getInputProps('synchronisation.instance')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <WordpressStatusSelect
                  readOnly={disabled}
                  disabled={disabled}
                  {...form.getInputProps('synchronisation.status')}
                />
              </Grid.Col>
            </Grid>
          </>
        )}
      </DetailedView>
    </form>
  );
}

export const routeConfig: RouteConfig = {
  path: ':id',
  label: 'Generated Post',
  Component: GeneratedPost,
  hidden: true,
};
