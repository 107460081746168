import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { name as authName, reducer as authReducer } from './auth';
import { name as siteSettingsName, reducer as siteSettingsReducer } from './site-settings/slice';
import { baseApi, rtkQueryErrorLogger } from './api';

// TODO: Move auth here as well.
const rootPersistConfig = {
  key: 'wg-root',
  storage,
  whitelist: [siteSettingsName],
};

const rootReducer = combineReducers({
  [authName]: authReducer,
  [siteSettingsName]: siteSettingsReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

export const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware).concat(rtkQueryErrorLogger),
});

export const persistor = persistStore(store);

export type AppState = ReturnType<typeof rootReducer>;
