/* eslint-disable */
import React from 'react';
import { MiddlewareAPI, isRejected } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';

const showErrorNotification = (title: string, message: string) => {
  showNotification({
    title,
    message,
    color: 'red',
    icon: <IconX />,
    autoClose: 5000,
  })
}

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger =
  (api: MiddlewareAPI) => (next: (action: any) => any) => (action: any) => {
    if (isRejected(action)) {
      const { status, error } = action.payload;

      if (status === 'FETCH_ERROR') {
        showErrorNotification('Fetch Error', `${error} - Check your network connection.`);
      } else if (status === 'TIMEOUT_ERROR') {
        showErrorNotification('Timeout Error', `${error} - Check your network connection.`);
      } else if (status === 403) {
        // TODO: Check token expiration, refresh token, and retry
      } else if (status === 500) {
        showErrorNotification('Server Error', 'Something unexpected happened.');
      }
    }

    return next(action);
  };
