import { useSelector } from 'react-redux';
import {
  selectAuthState,
  useIsAuthenticatedMutation,
  useLoginMutation,
  useLogoutMutation,
} from '../store/auth';

export const useAuth = () => {
  const [login, { error: loginErr, isLoading: loginLoading }] = useLoginMutation();
  const [logout, { error: logoutErr, isLoading: logoutLoading }] = useLogoutMutation();

  const { isAuthenticated, token } = useSelector(selectAuthState);

  const loginUser = (email: string, password: string) => {
    if (loginLoading || isAuthenticated) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    login({ email, password });
  };

  const logoutUser = () => {
    if (logoutLoading || !isAuthenticated) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    logout();
  };

  return {
    isAuthenticated,
    token,
    isLoading: loginLoading || logoutLoading,
    error: loginErr || logoutErr,
    login: loginUser,
    logout: logoutUser,
  };
};
