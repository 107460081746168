export const validateEmail = (val: string) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email');

export const validatePassword = (val: string) => {
  if (!val) {
    return 'Password is required';
  }

  if (val.length < 8) {
    return 'Password must be at least 8 characters long';
  }

  return null;
};

export const validateRequired = (name: string) => (val?: string) =>
  val ? null : `${name} is required`;
