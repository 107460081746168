import React, {useState} from 'react';
import {Anchor, createStyles, Divider, Group, Paper, PaperProps, Title} from '@mantine/core';
import GoogleButton from './components/GoogleButton';
import LoginForm from './components/LoginForm';
import {GoogleLogin} from '@dump-work/react-google-login';
import RegistrationForm from './components/RegistrationForm';
import {AppState} from "../../store";
import Cookies from "js-cookie";
import {Store} from 'redux';
import {useStore} from "react-redux";
import axios from "axios";


interface AuthenticationFormProps extends PaperProps {
    initialShowRegistration?: boolean;
}

function AuthenticationForm({initialShowRegistration, ...props}: AuthenticationFormProps) {
    const [showRegistration, setShowRegistration] = useState<boolean>(
        initialShowRegistration ?? false
    );
    const store = useStore() as Store<AppState>;


    const responseGoogle = (response: any) => {
        const selectToken = (state: AppState) => state.auth.token;
        const state: AppState = store.getState(); // Get the current state
        const token = selectToken(state);

        axios.post(`${process.env.REACT_APP_API_URL!}/api/v1/api/google_auth/`, {
            method: 'POST',
            data: {
                token: response.tokenObj.id_token
            }
        })
            .then(response => {
                if (response.data.success) {
                    localStorage.setItem('gwt_token', response.data.token);
                    localStorage.setItem('csrftoken', response.data.csrftoken);
                    // reload page
                    window.location.reload();
                } else {
                    // reload page
                    window.location.reload();
                }
            })
            .catch(error => {
                console.error("Error during Axios request:", error);
            });
    };


    return (
        <Paper pos="relative" radius="md" p="xl" withBorder {...props}>
            <Group grow mb="md" mt="md">
                <GoogleLogin
                    clientId="815048802723-t1klravek1ohn1kmp5st8rnfegc8usa8.apps.googleusercontent.com"
                    buttonText="Login with Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                />
            </Group>

            <Divider label="Or continue with email" labelPosition="center" my="lg"/>

            {showRegistration ? <RegistrationForm/> : <LoginForm/>}

            <Group position="apart" mt="xl">
                <Anchor
                    component="button"
                    type="button"
                    color="dimmed"
                    onClick={() => setShowRegistration((type) => !type)}
                    size="xs"
                >
                    {showRegistration ? 'Already have an account? Login' : "Don't have an account? Register"}
                </Anchor>
            </Group>
        </Paper>
    );
}

const useStyles = createStyles((theme) => ({
    wrapper: {
        minHeight: 900,
        backgroundSize: 'cover',
        backgroundImage:
            'url(https://images.unsplash.com/photo-1484242857719-4b9144542727?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&q=80)',
    },

    form: {
        borderRight: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
        }`,
        minHeight: 900,
        maxWidth: 450,
        paddingTop: 80,

        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            maxWidth: '100%',
        },
    },

    title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily ?? ''}`,
    },
}));

export default function Login({
                                  initialShowRegistration,
                              }: Pick<AuthenticationFormProps, 'initialShowRegistration'>) {
    const {classes} = useStyles();

    return (
        <div className={classes.wrapper}>
            <Paper className={classes.form} radius={0} p={30}>
                <Title order={2} className={classes.title} align="center" mt="md" mb={50}>
                    Welcome back to Word Genie!
                </Title>
                <AuthenticationForm initialShowRegistration={initialShowRegistration}/>
            </Paper>
        </div>
    );
}
