// components/DetailedView.tsx
import React, { ReactNode, useEffect, useState } from 'react';
import { IconArrowLeft, IconCheck, IconEdit, IconEye, IconTrash } from '@tabler/icons-react';
import { To, useLocation, useNavigate } from 'react-router-dom';
import {
  ActionIcon,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  Skeleton,
  Stack,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useConfirmDelete } from '../../../hooks/useConfirmDelete';

export type ViewMode = 'view' | 'edit';

interface ChildrenProps {
  viewMode: ViewMode;
  disabled: boolean;
}

interface DetailedViewProps {
  title?: ReactNode | string;
  children: (props: ChildrenProps) => ReactNode;
  onSave: () => void;
  onDelete: () => void;
  customIcons?: ReactNode;
  footerActions?: ReactNode;
  backRoute?: To;
  isLoading?: boolean;
  needsRedirect?: boolean;
  isSaved?: boolean;
  disableEditMode?: boolean;
}

const useDetailedView = () => {
  const location = useLocation();
  const [viewMode, setViewMode] = useState<ViewMode>('view');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get('viewMode');
    if (mode === 'view' || mode === 'edit') {
      setViewMode(mode);
    }
  }, [location.search]);

  const toggleViewMode = () => {
    setViewMode(viewMode === 'view' ? 'edit' : 'view');
  };

  return { viewMode, toggleViewMode };
};

const DetailedView: React.FC<DetailedViewProps> = ({
  title,
  children,
  onSave,
  onDelete,
  customIcons,
  footerActions,
  backRoute,
  isLoading,
  needsRedirect,
  isSaved,
  disableEditMode,
}) => {
  const navigate = useNavigate();
  const { viewMode, toggleViewMode } = useDetailedView();
  const onDeleteWithConfirm = useConfirmDelete({ onDelete });

  const navigateBack = () => {
    if (backRoute) {
      navigate(backRoute);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (needsRedirect) {
      navigateBack();
    }
  }, [needsRedirect]);

  useEffect(() => {
    if (isSaved) {
      showNotification({
        title: 'Saved Successfully',
        color: 'green',
        icon: <IconCheck />,
        autoClose: 3000,
        message: 'The changes have been saved successfully.',
      });
    }
  }, [isSaved]);

  return (
    <Paper withBorder p="md" shadow="xs" sx={{ maxWidth: '100%', maxHeight: '100%' }}>
      <ActionIcon onClick={navigateBack}>
        <IconArrowLeft />
      </ActionIcon>

      <Container>
        <Stack>
          <Grid align="center">
            <Grid.Col span="content">
              {title === undefined ? (
                <Skeleton width={500} height={20} />
              ) : typeof title === 'string' ? (
                <Title style={{ margin: 0 }} order={2}>
                  {title}
                </Title>
              ) : (
                title
              )}
            </Grid.Col>

            <Grid.Col span="auto">
              <Group position="right">
                {customIcons}
                <ActionIcon onClick={toggleViewMode}>
                  {viewMode !== 'view' ? <IconEye /> : <IconEdit />}
                </ActionIcon>

                <ActionIcon onClick={onDeleteWithConfirm}>
                  <IconTrash />
                </ActionIcon>
              </Group>
            </Grid.Col>
          </Grid>
          <Divider />

          <Box pos="relative">
            <LoadingOverlay zIndex={3} visible={!!isLoading} />

            {children({ viewMode, disabled: viewMode === 'view' })}
          </Box>

          {viewMode === 'edit' && !disableEditMode && (
            <>
              <Divider />
              <Group position="right">
                {footerActions}
                <Button type="button" onClick={onSave} loading={isLoading}>
                  Save
                </Button>
              </Group>
            </>
          )}
        </Stack>
      </Container>
    </Paper>
  );
};

export default DetailedView;
