import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api, LoginResponse } from './api';

const TOKEN_KEY = 'gwt_token';
export interface AuthState {
  isAuthenticated: boolean;
  token?: string;
}

const token = localStorage.getItem(TOKEN_KEY) ?? undefined;

// Check if the token is still valid.
if (token) {
  api.endpoints.isAuthenticated.initiate();
}

const initialState: AuthState = {
  isAuthenticated: !!token,
  token,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.login.matchFulfilled,
      (state, { payload }: PayloadAction<LoginResponse>) => {
        localStorage.setItem(TOKEN_KEY, payload.key);
        return {
          ...state,
          isAuthenticated: true,
          token: payload.key,
        };
      }
    );
    builder.addMatcher(api.endpoints.logout.matchPending, (state) => {
      localStorage.removeItem(TOKEN_KEY);
      return {
        ...state,
        isAuthenticated: false,
        token: undefined,
      };
    });

    builder.addMatcher(api.endpoints.isAuthenticated.matchRejected, (state) => {
      localStorage.removeItem(TOKEN_KEY);
      return {
        ...state,
        isAuthenticated: false,
      };
    });
  },
});

export const { reducer, name } = authSlice;
