import { baseApi } from '../api';
import { Announcement } from '../../model/Announcement';

export const api = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnnouncements: builder.query<Announcement[], void>({
      query: () => 'general/announcements/',
      providesTags: [{ type: 'Announcement', id: 'LIST' }],
    }),
  }),
});

export const { useGetAnnouncementsQuery } = api;
