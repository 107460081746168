import { useForm } from '@mantine/form';
import {
  Button,
  Checkbox,
  Grid,
  LoadingOverlay,
  PasswordInput,
  TextInput,
  Text,
} from '@mantine/core';
import React from 'react';
import { validateEmail, validatePassword, validateRequired } from '../../../validators';
import { useRegistrationMutation } from '../../../store/auth';
import ErrorText from '../../../components/ErrorText';
import { useFormApiErrorHandler } from '../../../hooks/useFormApiErrorHandler';

interface RegistrationFormValues {
  email: string;
  firstName: string;
  lastName: string;
  password1: string;
  password2: string;
  agreedToTerms: boolean;
}

export default function RegistrationForm() {
  const form = useForm<RegistrationFormValues>({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      password1: '',
      password2: '',
      agreedToTerms: false,
    },

    validate: {
      email: validateEmail,
      firstName: validateRequired('First name'),
      lastName: validateRequired('Last name'),
      password1: validatePassword,
      password2: (val, values) =>
        validateRequired('Confirm Password')(val) ||
        (val === values.password1 ? null : 'Passwords do not match'),
      agreedToTerms: (val) => (val ? null : 'You must agree to the terms and conditions'),
    },
  });

  const [registration, { error, isLoading, isSuccess }] = useRegistrationMutation();
  useFormApiErrorHandler(form, error);

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        registration(values);
      })}
      noValidate
    >
      <LoadingOverlay visible={isLoading} overlayBlur={1} />

      <Grid gutter="xs">
        <Grid.Col>
          <TextInput
            required
            autoComplete="email"
            label="Email"
            placeholder="max@mustermann.de"
            {...form.getInputProps('email')}
          />
        </Grid.Col>

        <Grid.Col>
          <PasswordInput
            required
            autoComplete="new-password"
            label="Password"
            placeholder="Your password"
            {...form.getInputProps('password1')}
          />
        </Grid.Col>

        <Grid.Col>
          <PasswordInput
            required
            autoComplete="new-password"
            label="Confirm Password"
            placeholder="Your password"
            {...form.getInputProps('password2')}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput
            required
            autoComplete="given-name"
            label="First Name"
            placeholder="Max"
            {...form.getInputProps('firstName')}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput
            required
            autoComplete="family-name"
            label="Last Name"
            placeholder="Mustermann"
            {...form.getInputProps('lastName')}
          />
        </Grid.Col>

        <Grid.Col>
          {
            // TODO - Required asteriks not working
          }
          <Checkbox
            required
            label="I agree to terms and conditions"
            {...form.getInputProps('agreedToTerms')}
          />
        </Grid.Col>

        {form.errors.nonFieldErrors && <ErrorText>{form.errors.nonFieldErrors}</ErrorText>}

        {/* TODO - Handle this properly */}

        {isSuccess && (
          <Text color="green">
            Registration successful. You can now login with your email and password.
          </Text>
        )}

        <Grid.Col>
          <Button type="submit">Register</Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
