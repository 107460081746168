import { useEffect } from 'react';
import { Rating } from '@mantine/core';
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../src/store';
import Cookies from 'js-cookie';

interface RatePostProps {
  rating: number;
  id: string
}

export default function RatePost(props: RatePostProps) {
    const [ratingValue, setRatingValue] = useState(props.rating);
    const selectToken = (state: AppState) => state.auth.token;
    const token = useSelector(selectToken);
    const csrfToken = Cookies.get('csrftoken') ?? '';

    useEffect(() => {
        // Function to send the request to your API
        const sendRatingToAPI = async () => {
            
            const headers: Record<string, string> = {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
            };

            if (token) {
                headers['authorization'] = `Token ${token}`;
            }
            
            const payload = {"id": props.id, "rating": ratingValue}
            

            fetch(`${process.env.REACT_APP_API_URL}/api/v1/generations/rating/`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(payload)
            })
                .then(response => {
                    if (!response.ok) throw new Error('Network error');
                    return response.json();
                })
                .then(data => {
                    if (data.success) {
                        console.log("success");
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }

        // Call the function to send the rating to the API
        if (ratingValue !== props.rating) {
        sendRatingToAPI();
        window.location.reload();
        }
    }, [ratingValue]);

    return (
        <div>
            <h3>Rating</h3>
            <Rating value={ratingValue} onChange={setRatingValue} size="xl" />
        </div>
    );
}
