// eslint-disable-next-line no-shadow
export enum InstanceType {
  WORDPRESS = 'wordpress',
}

export interface Instance {
  id: string;
  name: string;
  host: string;
  type: InstanceType;

  readonly isValid?: boolean;
  readonly created?: string;
  readonly modified?: string;
}

export interface WordPressInstance extends Instance {
  type: InstanceType.WORDPRESS;
  username: string;
  password: string;
}
