import { UseFormReturnType } from '@mantine/form';
import { FetchBaseQueryError } from '@reduxjs/toolkit/src/query/fetchBaseQuery';
import { SerializedError } from '@reduxjs/toolkit';
import React, { ReactNode, useEffect } from 'react';

export interface FormError<T> {
  status: number;
  data: Partial<T> & {
    nonFieldError?: string[];
  };
}

const handleErrorData = <T extends object>(data: T) => {
  const result: { [K in keyof T]?: string | ReactNode } = {};
  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      result[key as keyof T] = (
        <>
          {value.map((v, i) => {
            if (i > 0) {
              return (
                <>
                  <br />
                  <span key={v as string}>{v}</span>
                </>
              );
            }
            return <span key={v as string}>{v}</span>;
          })}
        </>
      );
    } else {
      result[key as keyof T] = value as string;
    }
  });

  return result;
};

export function useFormApiErrorHandler<T>(
  form: UseFormReturnType<T>,
  apiErrors: FetchBaseQueryError | SerializedError | undefined
) {
  useEffect(() => {
    // Other errors are handled by the middleware.
    if (apiErrors) {
      if ('status' in apiErrors) {
        if (apiErrors.status === 400) {
          const typedErr = { ...apiErrors } as FormError<T>;
          form.setErrors(handleErrorData(typedErr.data));
        }
      }
    }
  }, [apiErrors]);
}
