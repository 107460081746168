import { useSelector, useDispatch } from 'react-redux';
import { ColorScheme } from '@mantine/core';
import { hideAnnouncement, selectSettingsState, toggleColorScheme } from '../store/site-settings';

export const useSiteSettings = () => {
  const dispatch = useDispatch();
  const siteSettings = useSelector(selectSettingsState);

  const toggleScheme = (value?: ColorScheme) => {
    dispatch(toggleColorScheme(value));
  };

  const hideA = (id: string) => {
    dispatch(hideAnnouncement(id));
  };

  return {
    siteSettings,
    toggleColorScheme: toggleScheme,
    hideAnnouncement: hideA,
    isDarkMode: siteSettings.colorScheme === 'light',
  };
};
