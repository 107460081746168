import { useDispatch, useSelector } from 'react-redux';
import {AppState} from '../../../../store';

import { Button } from '@mantine/core'; // Import your button component
import Cookies from 'js-cookie';

const CheckoutForm = () => {
  const selectToken = (state: AppState) => state.auth.token;
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const csrfToken = Cookies.get('csrftoken') ?? '';

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
    };

    if (token) {
      headers['authorization'] = `Token ${token}`;
    }

fetch(`${process.env.REACT_APP_API_URL}/api/v1/api/create-subscription/`, {
  method: 'POST',
  headers: headers,
  body: JSON.stringify(Object.fromEntries(formData)),
})
  .then(response => {
    if (!response.ok) throw new Error('Network error')
    return response.json()
  })
  .then(data => {
    // Check if the url exists in the response and redirect
    if (data.url) {
      window.location.href = data.url;
    }
  })
  .catch(error => {
    // Handle errors
  });
    };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="token" value={token} />
        <Button
          component="button"
          size="md"
          type="submit"
          color="blue"
          radius="xl"
        >
          💸 Buy Token 💸
        </Button>
      </form>
    </div>
  );
};

export default CheckoutForm;
