import { baseApi } from '../api';

export interface LoginResponse {
  key: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface RegisterRequest {
  email: string;
  password1: string;
  password2: string;
  firstName: string;
  lastName: string;
  agreedToTerms: boolean;
}

export const api = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    isAuthenticated: builder.mutation<void, void>({
      query: () => ({
        url: 'is-authenticated/',
        method: 'GET',
      }),
    }),

    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: 'login/',
        method: 'POST',
        body: credentials,
      }),
    }),

    logout: builder.mutation<void, void>({
      query: () => ({
        url: 'logout/',
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),

    registration: builder.mutation<LoginResponse, RegisterRequest>({
      query: (registrationRequest) => ({
        url: 'registration/',
        method: 'POST',
        body: registrationRequest,
      }),
    }),
  }),
});

export const {
  useIsAuthenticatedMutation,
  useLoginMutation,
  useRegistrationMutation,
  useLogoutMutation,
} = api;
