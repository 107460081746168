import React from 'react';
import { SelectProps } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useGetInstancesQuery } from '../store/instances/api';
import AsyncSelect from '../../../components/AsyncSelect';

export interface InstanceSelectProps extends Omit<SelectProps, 'data' | 'value' | 'onChange'> {
  value: string;
  onChange: (instance: string) => void;
}

export default function InstanceSelect({ value, error, onChange, ...props }: InstanceSelectProps) {
  const { data: instances, isLoading, isError, error: fetchError } = useGetInstancesQuery();

  const instanceOptions =
    instances
      ?.filter((instance) => instance.isValid)
      .map((instance) => ({
        value: instance.id,
        label: instance.name,
      })) ?? [];

  return (
    <AsyncSelect
      isError={isError}
      error={isError ? fetchError.toString() : error}
      data={instanceOptions}
      label="Select instance"
      placeholder="Select instance"
      value={value}
      isLoading={isLoading}
      onChange={onChange}
      searchable
      creatable
      getCreateLabel={(query) => `Create instance "${query}"`}
      onCreate={() => {
        modals.openContextModal({
          modal: 'instanceCreation',
          title: 'Create instance',
          innerProps: undefined,
        });
        return undefined;
      }}
      {...props}
    />
  );
}
