import React, {useEffect} from 'react';
import {useState} from 'react';
import {Avatar, Button, Card, createStyles, Grid, Group, Text, TextInput, Box, Overlay} from '@mantine/core';
import {notifications} from '@mantine/notifications';
import {RouteConfig} from '../../../../types/RouteConfig';
import {useAuth} from '../../../../hooks/useAuth';
import {useUser} from '../../../../hooks/useUser';
import axios from "axios";
import Cookies from "js-cookie";
import {useStore} from 'react-redux';
import {AppState} from '../../../../store';
import {Store} from 'redux';
import ProductDisplay from './BuyStripe';
import {useLocation} from 'react-router-dom';
import DeleteAccount from './DeleteAccount';
import CancelSubscription from './CancelSubscription';
import LifeTimePlan from './LifeTimePlan';

const selectToken = (state: AppState) => state.auth.token;

const useStyles = createStyles((theme) => ({
    card: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
    avatar: {
        border: `2px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white}`,
    },
}));

export function UserCardImage() {
    const {classes} = useStyles();
    const {user} = useUser({refetchOnMountOrArgChange: true});
    const {firstName, lastName, email, tokens, subscriptionStatus, lastPaymentDate, lifetimeDeal} = user ?? {};
    const [focused, setFocused] = useState(false);
    const [value, setValue] = useState("");
    const [disabled, setDisabled] = useState(false);
    const store = useStore() as Store<AppState>;
    const location = useLocation();

    const handleBlur = async () => {
        setDisabled(true); // disable the input field
        const state: AppState = store.getState(); // Get the current state
        const token = selectToken(state);
        const csrfToken = Cookies.get('csrftoken') ?? '';
        const headers: Record<string, string> = {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
        };
    
        if (token) {
            headers['authorization'] = `Token ${token}`;
        }
    
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL!}/api/v1/api/api_key/`, {api_key: value}, {headers});
            console.log(response);
    
            // If the request is successful
            notifications.show({
                title: 'Success',
                message: 'Your API key is saved and will disappear when you leave this page. You can change it by entering a different key here.',
            });
    
            console.log(response.data);
            setDisabled(true); // re-enable the input field
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // Handle Axios error here
                const errorMessage = error.response?.data?.message || 'There was an error processing your request.';
                notifications.show({
                    color: 'red',
                    title: 'Failed',
                    message: errorMessage,
                });
            } else {
                // Handle non-Axios error here
                notifications.show({
                    color: 'red',
                    title: 'Error',
                    message: 'An unexpected error occurred.',
                });
            }
            setDisabled(false); // re-enable the input field in case of error
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const message = urlParams.get('message');
        if (message) {
            if (message.toLowerCase().startsWith('false')) {
                notifications.show({
                    title: 'Error',
                    message: 'Something went wrong!',
                });
            } else if (message.toLowerCase().startsWith('true')) {
                notifications.show({
                    title: 'Success',
                    message: 'Your tokens should arrive soon!',
                });
            }
            else if (message.toLowerCase().startsWith('hassubscription')) {
                notifications.show({
                    title: 'Success',
                    message: 'User already has a subscription!',
                });
            }
            else if (message.toLowerCase().startsWith('cancelsubscriptionsuccess')) {
                notifications.show({
                    title: 'Success',
                    message: 'Subscription cancelled!',
                });
            }
            else if (message.toLowerCase().startsWith('cancelsubscriptionfailed')) {
                notifications.show({
                    title: 'Success',
                    message: 'Something went wrong, please contact customer support at info@wordgenie.de!',
                });
            }
        }
    }, [location.search, notifications]);


    return (
        <Card withBorder p="xl" radius="md" className={classes.card}>
            <h3>Profile</h3>
            <Card.Section
        h={140}
        style={{
          backgroundImage:
            'url(https://images.unsplash.com/photo-1495291916458-c12f594151e7?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=700&q=80)',
        }}
      />
      <Avatar
        variant="filled"
        size={80}
        radius={80}
        mx="auto"
        mt={-30}
        className={classes.avatar}
      />
      <Text ta="center" fz="lg" fw={500} mt="sm">
      {firstName} {lastName}
      </Text>
      <Text ta="center" fz="lg" fw={500} mt="sm">
        {email}
      </Text>
      <Text ta="center" fz="lg" fw={500} mt="sm">
      Tokens: {tokens}
      </Text>
      {subscriptionStatus === 'active' && (
        <>
      <Text ta="center" fz="lg" fw={500} mt="sm">
        Subscription Status: {subscriptionStatus}
        </Text>
        <Text ta="center" fz="lg" fw={500} mt="sm">
        Last Payment Date: {lastPaymentDate}
        </Text>
      <Text ta="center" fz="sm" c="dimmed">
      </Text>
      </>
      )}
            <h3>Pricing</h3>
            <Box
                sx={(theme) => ({
                    display: 'flex',
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                    textAlign: 'center',
                    padding: theme.spacing.xl,
                    borderRadius: theme.radius.md,
                })}>
                <Text align="center" size="sm" mt="sm">1 Token = 1 Post <br/> 0.25 Token = 30 Bulk Suggestions  <br/> 0.005 Token = 1 KW Tracking/day</Text>
            </Box>
            <div>
                <br/>
                <h3>Interested in a lifetime plan?</h3>
                {lifetimeDeal ? (
                    <>
                    <TextInput
                        size="lg"
                        rightSectionWidth={40}
                        label="OPENAI API KEY"
                        description="You're using your API-Key by adding it here and accept the terms of service."
                        value={value}
                        onChange={(event) => setValue(event.target.value)}
                        onFocus={() => setFocused(true)}
                        onBlur={handleBlur}
                        disabled={disabled}
                    />
                    </>
                ) : (
                    <>
                        <LifeTimePlan></LifeTimePlan>
                    </>
                )}
                <br/>
                <h3>Delete Account</h3>
                <Box
                    sx={(theme) => ({
                        display: 'flex',
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                        textAlign: 'center',
                        padding: theme.spacing.xl,
                        borderRadius: theme.radius.md,
                    })}>
                    <DeleteAccount/>
                </Box>
                {subscriptionStatus === 'active' && (
                    <>
                <h3>Cancel subscription</h3>
                <Box
                    sx={(theme) => ({
                        display: 'flex',
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                        textAlign: 'center',
                        padding: theme.spacing.xl,
                        borderRadius: theme.radius.md,
                    })}>
                    <CancelSubscription/>
                </Box>
                </>
                )}
            </div>
        </Card>
    )
        ;
}

export default function Profile() {
    return (
        <Grid columns={24}>
            <Grid.Col span={24}>
                <UserCardImage/>
            </Grid.Col>
        </Grid>
    );
}

export const ROUTE_PATH = 'profile';

export const routeConfig: RouteConfig = {
    path: ROUTE_PATH,
    label: 'Profile',
    Component: Profile,
    hidden: true,
};