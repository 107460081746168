import React, { useCallback } from 'react';
import { ActionIcon, Button, Tooltip } from '@mantine/core';
import { MRT_ColumnDef as MRT_COLDEF } from 'mantine-react-table';
import { useNavigate } from 'react-router-dom';
import { IconList, IconExternalLink } from '@tabler/icons-react';
import { RouteConfig } from '../../../../../../types/RouteConfig';
import { BlogPost } from '../../../../model/BlogPost';
import { useGetBlogPostsQuery } from '../../../../store/instance-posts/api';
import StyledTable from '../../../../components/StyledTable';
import { useGetInstancesQuery } from '../../../../store/instances/api';

const columns: MRT_COLDEF<BlogPost>[] = [
  {
    accessorFn: (blogPost) => `${blogPost.id}-${blogPost.instance}`,
    header: 'ID',
    accessorKey: 'id',
    size: 200,
  },
  {
    accessorKey: 'title',
    header: 'Title',
    size: 300,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    size: 50,
  },
];

export default function InstancePosts() {
  const { data, isLoading, isFetching } = useGetBlogPostsQuery();

  const onEdit = (post: BlogPost) => {
    const url = new URL(post.link ?? '');
    const wpUrl = `https://${url.host}/wp-admin/post.php?post=${post.id}&action=edit`;

    window.open(wpUrl, '_blank');
  };

  const onView = (post: BlogPost) => {
    window.open(post.link, '_blank');
  };

  return (
    <StyledTable<BlogPost>
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      data={data ?? []}
      onEdit={onEdit}
      onView={onView}
      getRowId={(blogPost) => blogPost.id}
    />
  );
}

export const ROUTE_PATH = 'all-blogs';

export const routeConfig: RouteConfig = {
  path: ROUTE_PATH,
  label: 'All Blogs',
  Icon: IconList,
  Component: InstancePosts,
};
