import React from 'react';
import { NavLink } from '@mantine/core';
import { useLocation, useNavigate, useResolvedPath } from 'react-router-dom';
import { RouteConfig } from '../../../types/RouteConfig';

function LinkItem({ path, label, Icon, children }: RouteConfig & { className?: string }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useResolvedPath(path);
  const active = location.pathname === pathname;

  const nonHiddenChildren = children?.filter(({ hidden }) => !hidden);
  const hasChildren = !!nonHiddenChildren && nonHiddenChildren?.length > 0;

  return (
    <NavLink
      label={label}
      pl="xs"
      icon={Icon && <Icon size={20} />}
      active={active}
      onClick={() => {
        if (!hasChildren) {
          navigate(path);
        }
      }}
    >
      {hasChildren &&
        nonHiddenChildren.map(({ path: childPath, ...child }) => (
          // eslint-disable-next-line no-use-before-define
          <NavbarItem key={path + childPath} path={`${path}/${childPath}`} {...child} />
        ))}
    </NavLink>
  );
}

export function NavbarItem({ hidden, ...route }: RouteConfig) {
  if (hidden) {
    return null;
  }

  return <LinkItem {...route} />;
}
