import React, { useEffect } from 'react';
import { Loader, Select, SelectProps } from '@mantine/core';
import { IconExclamationCircle } from '@tabler/icons-react';

interface AsyncSelectProps extends SelectProps {
  isLoading: boolean;
  isError?: boolean;
  loaderSize?: number;
}

export default function AsyncSelect({
  isLoading,
  isError,
  nothingFound,
  icon,
  creatable,
  defaultValue,
  onChange,
  loaderSize = 16,
  ...props
}: AsyncSelectProps) {
  useEffect(() => {
    if (!isLoading && defaultValue) {
      onChange?.(defaultValue);
    }
  }, [isLoading]);

  const StyledLoader = <Loader size={loaderSize} />;
  let Icon = icon;

  if (isLoading) {
    Icon = StyledLoader;
  } else if (isError) {
    Icon = <IconExclamationCircle size={loaderSize} />;
  }

  return (
    <Select
      creatable={!isLoading && creatable}
      icon={Icon}
      nothingFound={isLoading ? StyledLoader : nothingFound}
      defaultValue={defaultValue}
      onChange={onChange}
      {...props}
    />
  );
}
