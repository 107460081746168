import { useForm } from '@mantine/form';
import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  ModalProps,
  PasswordInput,
  Select,
  Stack,
  TextInput,
} from '@mantine/core';
import React from 'react';
import { ContextModalProps } from '@mantine/modals';
import { useFormApiErrorHandler } from '../../../../hooks/useFormApiErrorHandler';
import ErrorText from '../../../../components/ErrorText';
import { useUpdateGeneratedPostMutation } from '../../store/generated-posts/api';
import { GeneratedPostListItem, Synchronisation, WordpressStatus } from '../../model/GeneratedPost';
import InstanceSelect from '../InstanceSelect';
import { validateRequired } from '../../../../validators';
import WordpressStatusSelect from '../WordpressStatusSelect';

export type SynchronisationInnerProps =
  | {
      generatedPost: GeneratedPostListItem;
    }
  | {
      generatedPosts: GeneratedPostListItem[];
    };

type SynchronisationModalProps = ContextModalProps<SynchronisationInnerProps>;

export default function SynchronisationContextModal({
  innerProps,
  context,
  id,
}: SynchronisationModalProps) {
  const [updateGeneratedPost, { isLoading, error }] = useUpdateGeneratedPostMutation();

  const form = useForm<Synchronisation>({
    initialValues:
      'generatedPost' in innerProps
        ? {
            ...innerProps.generatedPost?.synchronisation,
          }
        : undefined,
    validate: {
      status: validateRequired('Post Status'),
    },
  });

  useFormApiErrorHandler(form, error);

  const onClose = () => {
    context.closeModal(id);
  };

  const onSubmit = form.onSubmit((values) => {
    Promise.all(
      'generatedPosts' in innerProps
        ? innerProps.generatedPosts.map((post) =>
            updateGeneratedPost({ id: post.id, synchronisation: values })
          )
        : [updateGeneratedPost({ id: innerProps.generatedPost.id, synchronisation: values })]
    ).then((res) => {
      if (!('error' in res)) {
        form.reset();
        onClose();
      }
    });
  });

  return (
    <>
      <LoadingOverlay visible={isLoading} overlayBlur={1} />

      <form onSubmit={onSubmit} noValidate>
        <Stack>
          <InstanceSelect required clearable withinPortal {...form.getInputProps('instance')} />
          <WordpressStatusSelect
            defaultValue={WordpressStatus.DRAFT}
            required
            {...form.getInputProps('status')}
          />

          {form.errors.nonFieldErrors && <ErrorText>{form.errors.nonFieldErrors}</ErrorText>}

          <Group position="right">
            <Button variant="default" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Synchronise</Button>
          </Group>
        </Stack>
      </form>
    </>
  );
}
