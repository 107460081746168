import { createSlice } from '@reduxjs/toolkit';
import { ColorScheme } from '@mantine/core';

// TODO: create an object on the user and store the settings on the server.

export interface SiteSettingsState {
  colorScheme: ColorScheme;
  hiddenAnnouncementIds: string[];
}

const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

const initialState: SiteSettingsState = {
  colorScheme: isDarkMode ? 'dark' : 'light',
  hiddenAnnouncementIds: [],
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleColorScheme: (state, action) => ({
      ...state,
      colorScheme: action.payload ?? state.colorScheme === 'dark' ? 'light' : 'dark',
    }),

    hideAnnouncement: (state, action) => ({
      ...state,
      hiddenAnnouncementIds: [...state.hiddenAnnouncementIds, action.payload],
    }),
  },
});

export const { reducer, name } = settingsSlice;

export const { toggleColorScheme, hideAnnouncement } = settingsSlice.actions;
