import { RouteConfig } from '../../../../types/RouteConfig';
import { IconArrowDown, IconArrowUp, IconApi } from '@tabler/icons-react';
import { Card, Group, Timeline, Text, Title, Container, Button } from '@mantine/core';


export default function ApiPage() {
  return (
<Card withBorder>
  <Title>Explore Our Services</Title>
  <br/>
  Please send an <a href="mailto:info@wordgenie.de">email</a> if you're interested in using our API for bulk, SEO-optimized blog posts. Our offerings include:
  <br/>
  <br/>
  <ul>
    <li><b>Humanized texts</b> to bypass AI detection algorithms.</li>
    <li>Comprehensive <b>texts exceeding 4000 words</b> for in-depth analysis.</li>
    <li>Embedded <b>image generation</b> to enhance textual content visually.</li>
    <li><b>Social media content</b> tailored for platforms like Instagram to boost engagement.</li>
    <li><b>SEO-focused content audits</b> to identify and leverage improvement opportunities.</li>
    <li>Engaging, <b>SEO-optimized product descriptions</b> to increase e-commerce sales.</li>
  </ul>
  Leverage WordGenie's expertise to broaden your content strategy and online presence.
</Card>
  );
}


const ROUTE_PATH = 'api';

export const routeConfig: RouteConfig = {
    path: ROUTE_PATH,
    Icon: IconApi,
    label: 'API',
    Component: ApiPage,
    hidden: false,
};
