import React from 'react';
// eslint-disable-next-line camelcase
import { MRT_ColumnDef as MRT_COLDEF } from 'mantine-react-table';
import { Button } from '@mantine/core';
import { IconBrandWordpress, IconPlus } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { RouteConfig } from '../../../../types/RouteConfig';
import { useDeleteInstanceMutation, useGetInstancesQuery } from '../../store/instances/api';
import InstanceCreationModal from '../../components/modal/InstanceCreationModal';
import StyledTable from '../../components/StyledTable';
import { routeConfig as instanceRouteConfig } from './pages/Instance';
import { Instance } from '../../model/Instance';

const columns: MRT_COLDEF<Instance>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorKey: 'created',
    header: 'Created',
  },
  {
    accessorKey: 'modified',
    header: 'Modified',
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'host',
    header: 'Host',
  },
  {
    accessorKey: 'isValid',
    header: 'Connection works',
    accessorFn: (instance) => (instance.isValid ? 'Yes' : 'No'),
  },
];

export default function Instances() {
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const { data, isLoading, error, isFetching } = useGetInstancesQuery();
  const [delInstance, { isLoading: isDeleting }] = useDeleteInstanceMutation();

  const onView = (instance: Instance) => {
    navigate(instance.id);
  };

  const onEdit = (instance: Instance) => {
    navigate(`${instance.id}?viewMode=edit`);
  };

  const onDelete = (instance: Instance) => {
    delInstance(instance.id);
  };

  return (
    <>
      <StyledTable
        isLoading={isDeleting || isLoading}
        isFetching={isFetching}
        error={error}
        columns={columns}
        data={data ?? []}
        getRowId={(instance) => instance.id}
        onView={onView}
        onEdit={onEdit}
        onDelete={onDelete}
        renderTopToolbarCustomActions={() => (
          <Button onClick={open} leftIcon={<IconPlus />}>
            Add Instance
          </Button>
        )}
      />

      <InstanceCreationModal opened={opened} onClose={close} />
    </>
  );
}

export const ROUTE_PATH = 'instances';

export const routeConfig: RouteConfig = {
  path: ROUTE_PATH,
  Component: Instances,
  Icon: IconBrandWordpress,
  label: 'Wordpress Instances',
  children: [instanceRouteConfig],
};
