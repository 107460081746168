import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ColorSchemeProvider, MantineProvider, MantineTheme } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import ProtectedRoute from './components/ProtectedRoute';
import { useAuth } from './hooks/useAuth';
import NestedApp from './pages/app/NestedApp';
import Login from './pages/login/Login';
import { useSiteSettings } from './hooks/useSiteSettings';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


function RoutesApp() {
  const { isAuthenticated } = useAuth();

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <Routes>
        <Route
          path="wg/*"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <NestedApp />
            </ProtectedRoute>
          }
        />

        <Route
          path="login"
          element={
            <ProtectedRoute isAuthenticated={!isAuthenticated} redirectTo="/wg">
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path="register"
          element={
            <ProtectedRoute isAuthenticated={!isAuthenticated} redirectTo="/wg">
              <Login initialShowRegistration />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to={isAuthenticated ? '/wg' : '/login'} />} />
      </Routes>
    </div>
  );
}

const globalStyles = (theme: MantineTheme) => ({});

export default function App() {
  const { siteSettings, toggleColorScheme } = useSiteSettings();
  const { colorScheme } = siteSettings;

  return (
    <>
      <Notifications />
      <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
        <MantineProvider
          theme={{ colorScheme, globalStyles, fontFamily: 'Roboto, sans-serif' }}
          withGlobalStyles
          withNormalizeCSS
        >
          <BrowserRouter>
            <RoutesApp />
          </BrowserRouter>
        </MantineProvider>
      </ColorSchemeProvider>
    </>
  );
}
