import React from 'react';
import { Select, SelectProps } from '@mantine/core';
import { WordpressStatus } from '../model/GeneratedPost';

export interface WordpressStatusSelectProps extends Omit<SelectProps, 'data'> {
  value: WordpressStatus;
  onChange: (status: WordpressStatus) => void;
}

const data = Object.values(WordpressStatus)
  .map((status) => ({
    label: status.charAt(0).toUpperCase() + status.slice(1),
    value: status,
  }))
  .filter(
    (status) =>
      !(status.value === WordpressStatus.FUTURE || status.value === WordpressStatus.PENDING)
  );

export default function WordpressStatusSelect({
  value,
  onChange,
  ...props
}: WordpressStatusSelectProps) {
  return (
    <Select
      label="Post Status"
      placeholder="draft"
      searchable
      required
      withinPortal
      data={data}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
}
