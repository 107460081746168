import { openConfirmModal } from '@mantine/modals';
import { ModalProps, Text } from '@mantine/core';
import React from 'react';

interface UseConfirmDeleteProps<T> {
  onDelete?: (data: T) => void;
  modalProps?: ModalProps;
}

export function useConfirmDelete<T>({ onDelete, modalProps }: UseConfirmDeleteProps<T>) {
  const onDeleteWithConfirm = (data: T) => {
    openConfirmModal({
      title: 'Are you sure?',
      children: <Text size="sm">This action cannot be undone.</Text>,
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => onDelete?.(data),
      overlayProps: {
        center: true,
        blur: 0.5,
      },
      ...modalProps,
    });
  };

  return onDeleteWithConfirm;
}
