import { baseApi } from '../api';
import { User } from '../../model/User';
import { Feedback } from '../../pages/app/model/Feedback';

export const api = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => 'user/',
      providesTags: ['User'],
    }),

    updateUser: builder.mutation<User, User>({
      query: (user: User) => ({
        url: 'user/',
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: () => ['User'],
    }),

    addFeedback: builder.mutation<void, Feedback>({
      query: (feedback: Feedback) => ({
        url: 'general/feedback/',
        method: 'POST',
        body: feedback,
      }),
    }),
  }),
});

export const { useGetUserQuery, useUpdateUserMutation, useAddFeedbackMutation } = api;
